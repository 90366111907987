/** This Styling For USP Section in WenTravel Tenant Only **/

.#{$namespace} {
  .c-wentravel-usp {
    .usp-image {
      width: 80px;
      height: 80px;

      @include mobile {
        width: 56px;
        height: 56px;
      }
    }
  }
}
