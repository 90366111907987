@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

// NOTE: overide radio button on landing page only
.trv-flights-landing-page .c-flight-search-form .flight-type-item .t-input-control-radio > .t-input-control-indicator {
  background: $color-white !important;
  border-color: #CECECE;
}

.c-flight-search-form {
  display: block;

  & .flight {
    &-type {
      &-item {
        &.active {
          color: #FFF !important;
        }

        @include mobile {
          width: auto !important;
        }
      }
    }

    &-search {
      &-form {
        & .t-btn-primary {
          @include media-down(xs) {
            width: 100%;
          }
        }

        ::v-deep {
          & .t-input-global-datepicker {
            min-width: 100% !important;
            max-width: 100% !important;
          }

          & .c-datepicker,
          & .c-datepicker-range {
            & .el-icon-date {
              display: none !important;
            }

            & .t-input-text-tinted-left {
              padding-left: 12px !important;
            }
          }

          & .c-ajax-auto-complete {
            width: 45%;

            @include mobile {
              width: 100% !important;
              float: left !important;
            }
          }

          .ajax-auto-complete-input {
            &.has-error {
              border: 2px solid $color-danger;

              &:focus,
              &:active {
                border: 2px solid $color-danger !important;
              }
            }
          }

          & .c-flight-passanger-counter {
            & .t-input-text {
              @include placeholder {
                color: $color-grey-darkest !important;
              }
            }
          }
        }
      }
    }

    &-route {
      margin-bottom: 12px;

      @include media-up(lg) {
        margin-bottom: 0;
      }

      &-departure {
        margin-bottom: 12px;

        @include media-up(lg) {
          margin-bottom: 0;
        }

        ::v-deep {
          & .ajax-auto-complete-input {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;

            @include mobile {
              border-top-right-radius: $radius !important;
              border-bottom-right-radius: $radius !important;
            }
          }
        }
      }

      &-action {
        width: 10%;
      }

      &-switcher-container {
        display: none;

        @include media-up(md) {
          display: table-cell;
        }
      }

      &-switcher {
        border-top: 1px solid $primary-border-color;
        border-bottom: 1px solid $primary-border-color;
        height: 40px;

        &-icon {
          height: 36px;
          line-height: 36px !important;
        }
      }

      &-arrival {
        ::v-deep {
          & .ajax-auto-complete-input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;

            @include mobile {
              border-top-left-radius: $radius !important;
              border-bottom-left-radius: $radius !important;
            }
          }
        }
      }
    }
  }

  & .t-departure-input-section {
    padding-right: 0 !important;
  }

  & .t-return-input-section {
    padding-left: 0 !important;
  }
}
