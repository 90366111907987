/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 768px) {
  .#{$namespace} {
    & .c-section-title-button {
      & .section-title-button {
        &__action {
          border: none !important;
          padding: 0;

          &:hover {
            background: unset;
            color: $color-secondary;
          }
        }
      }

      & .w-auto-mobile {
        width: auto;
      }

      & .w-35-mobile {
        width: 35%;
      }

      & .t-heading-2 {
        font-size: 20px;
      }

      & .t-v-align-middle {
        &.t-v-align-top-mobile {
          vertical-align: top !important;
          padding-top: 6px;
        }
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-section-title-button {
      & .description {
        font-size: 14px;
      }

      & .sub-header {
        font-size: 14px;

        div,
        p,
        span {
          font-size: 14px;
        }
      }
    }
  }
}
