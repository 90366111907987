@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "../../variables.scss";

.#{$namespace} {
  .tooltip--input-form {
    background-color: $color-danger;
    border-radius: 4px;
    color: $color-white;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    margin-top: 6px;
    padding: 4px 8px;
    position: relative;

    &::before {
      content: '';
      bottom: 100%;
      border-bottom: 4px solid $color-danger;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      height: 0;
      position: absolute;
      top: -4px;
      left: 8px;
      width: 0;
    }

    &.no-carret::before {
      content: none;
    }
  }
}
