/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Home Hero Main **/
.#{$namespace} {
  & .c-home-hero-main {
    min-height: 440px;
    max-height: 440px;

    &:last-child {
      padding-bottom: 64px;
    }

    & .#{$prefix} {
      &-hero-main {
        &-content {
          min-height: 440px;
          max-height: 440px;

          &-list {
            &-title-wrapper {
              vertical-align: middle;
            }
          }
        }

        &-background {
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            display: block;
            background: rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }

  &.t-themes--10Travlr {
    & .t-hero-main-content-list-title {
      & .t-heading-1 {
        @include media-up(lg) {
          font-size: 56px;
          line-height: 67px;
        }
      }

      & .t-body-text {
        @include media-up(md) {
          font-size: 18px;
          margin-top: 10px;
        }

        @include media-up(lg) {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }
}

/* ************************************ */
