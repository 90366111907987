@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{$travlr-ui} {
  .c-section {
    & .t-heading-3 {
      font-size: 20px;

      @include media-up(md) {
        font-size: 24px;
      }
    }

    & .t-btn-small {
      line-height: 1.4;
      height: auto;
      padding: 8px 16px !important;
      text-align: left;
    }

    &.c-section--multiple {
      & .t-container {
        @include media-up(sm) {
          padding-right: 24px;
        }

        @include media-up(md) {
          padding-right: 32px;
        }

        @include media-up(xl) {
          padding-right: 8px;
        }
      }

      & .section-title {
        @include media-up(sm) {
          padding-right: 0;
        }
      }

      & .c-carousel {
        margin-right: -8px;

        @include media-up(sm) {
          margin-right: -16px;
        }

        @include media-up(md) {
          margin-right: 0;
          padding-right: 8px;
        }

        @include media-up(xl) {
          padding-right: 0;
        }
      }
    }

    &.c-section--three {
      .flickity-slider {
        display: flex;
        flex-flow: wrap;
      }

      & .c-carousel {
        & .carousel-item {
          align-items: stretch;
          min-height: 100%;

          @include media-up(md) {
            width: 47%;
          }

          @include media-up(lg) {
            width: 33.33333%;
          }
        }
      }
    }

    &.c-section--four {
      .flickity-slider {
        display: flex;
        flex-flow: wrap;
      }

      & .c-carousel {
        & .carousel-item {
          align-items: stretch;
          min-height: 100%;

          @include media-up(md) {
            width: 47%;
          }

          @include media-up(lg) {
            width: 32%;
          }

          @include media-up(xl) {
            width: 25%;
          }
        }
      }
    }
  }
}
