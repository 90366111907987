@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  & .banner {
    &__image {
      height: auto !important;
      width: 100% !important;
    }

    &__inner {
      @include media-up(md) {
        max-height: 440px;
      }
    }

    &--fullwidth {
      & .banner {
        &__inner {
          @include media-up(md) {
            max-height: 700px;
          }
        }
      }
    }
  }
}
