@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.c-banner-app {
  & .app-logo-link {
    border-radius: 24px;
    display: block;
    height: 98px;
    margin-bottom: 14px;
    overflow: hidden;
    width: 98px;

    @media (min-width: $screen-sm-min) {
      height: 142px;
      width: 142px;
    }

    img {
      left: -1px;
      margin-bottom: 0;
      position: relative;
      top: -1px;
    }
  }

  & .download-wrapper {
    & a {
      margin-bottom: 5px;

      @media (min-width: $screen-sm-min) {
        display: table-cell;
        margin-bottom: 0;
      }

      img {
        border-radius: 5px;
      }
    }
  }
}
