.c-usp-section-layer-widget {
  /deep/ {
    .c-image {
      width: 56px;
    }

    .usp-image {
      width: 56px;
      height: 56px;
      object-fit: contain;
    }
  }

  .t-inner-html {
    /deep/ {
      p {
        margin-bottom: 0;
      }
    }
  }
}
