/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Card **/
.#{$namespace} {
  & .c-card {
    &.card {
      &-info {
        & .#{$prefix} {
          &-card-title {
            min-height: 320px;
            max-height: 320px;
          }
        }
      }

      &-no-background {
        background: transparent;
        min-height: 245px;
        max-height: 245px;
        overflow: hidden;

        @include box-shadow(none);

        & .t-card-content-header {
          border-radius: 4px;
          overflow: hidden;
          position: relative;
        }

        & .t-card-content-body {
          background: transparent;
          margin-top: 8px;
          padding: 0;

          &-title {
            min-height: 0;
          }
        }

        & .t-card-content-footer {
          min-height: 0;
          max-height: 68px;
          overflow: hidden;
          background: transparent;
          padding: 0;

          & .description {
            color: $color-grey-dark;
            font-size: 12px;
            margin-top: 4px;
            line-height: 20px;
          }
        }
      }
    }

    & .t-card-label-container {
      position: absolute;
      background-color: rgba(0, 98, 114, 0.9);
      color: $color-white;
      top: 0;
      right: 24px;
      min-width: 150px;
      max-width: 87%;
      text-align: center;
      padding: 8px;
      margin-left: 24px;
      z-index: 2;

      & .title {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  & .c-listing-rating {
    width: 100%;
    height: 18px;

    & .star-rating {
      &-text {
        padding-right: 5px;
        color: $color-front-star;
      }
    }

    & .icon-star-rating-filled {
      font-size: 16px;
      display: table-cell;
    }

    & .back-stars {
      color: $color-back-star;
      position: relative;
    }

    & .front-stars {
      color: $color-front-star;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  // Image as Background Image Handler
  .t-card-content-header,
  .t-card-title-small {
    height: 170px;
  }

  .t-card-title-large {
    height: 230px;
  }

  .t-card-image-handler {
    width: 100%;
    object-fit: cover;
  }

  .t-card-large-bottom-title-image-handler {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }

  .t-card-large-center-title-image-handler {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
    width: 100%;
  }

  .t-object-fit-cover {
    object-fit: cover;
  }
}

/* ************************************ */
