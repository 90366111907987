@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

@mixin disabled {
  color: $color-grey-darkest !important;
  cursor: not-allowed;
  opacity: 0.45;
  pointer-events: none;
}

.travlr-ui {
  .counter {
    display: table;
    width: 100%;

    &::after {
      content: "";
      clear: both;
      display: block;
    }

    &.active {
      & .counter {
        &__box,
        &__field {
          border-color: $color-primary;
        }

        &__button {
          &:hover {
            color: $color-primary;
          }
        }
      }
    }

    &.disabled {
      @include disabled;

      .counter__button {
        @include disabled;
      }
    }

    &--min-disabled {
      & .counter__button--min {
        @include disabled;
      }
    }

    &--plus-disabled {
      & .counter__button--plus {
        @include disabled;
      }
    }

    &__label {
      color: $color-grey-darkest;
      display: table-cell;
      font-size: 14px;
      padding-right: 8px;
      vertical-align: middle;
    }

    &__box {
      border: 1px solid $color-grey-light;
      border-radius: 4px;
      color: $color-grey-darkest;
      display: table-cell;
      height: 36px;
      overflow: hidden;
      vertical-align: middle;
      transition: all 0.2s ease;
      width: 130px;

      > * {
        display: block;
        float: left;
        height: 100%;
      }
    }

    &__field {
      background-color: $color-white;
      border-bottom: none;
      border-left: 1px solid $color-grey-light;
      border-right: 1px solid $color-grey-light;
      border-radius: 0;
      border-top: none;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0;
      padding-top: 0;
      text-align: center;
      transition: all 0.2s ease;
      width: 40%;

      &:focus {
        box-shadow: none;
        outline: 0;
      }
    }

    &__button {
      background-color: $color-grey-lightest;
      cursor: pointer;
      text-align: center;
      transition: all 0.2s ease;
      width: 30%;

      & .t-icon {
        line-height: 36px;
      }
    }
  }
}
