@charset "utf-8";

@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "@/Library/UiKit/src/scss/uikit/elements/forms/_forms.scss";

.c-deal-search-auto-complete {
  .multiselect {
    & /deep/ {
      .multiselect__option--selected {
        font-weight: 400;
        background: none;
      }

      .multiselect__select {
        display: none !important;
      }

      .multiselect__content-wrapper {
        border-color: $input-border-color;
        z-index: 100;
      }

      .multiselect-notfound {
        color: #8C9497;
      }

      .multiselect__input {
        color: $color-grey-darkest;
        font-size: 14px;
        padding-left: 0;
        margin-bottom: 0;
        min-height: auto;
        line-height: normal;
        border-radius: 0;
        vertical-align: initial;

        @include placeholder {
          color: $color-grey-dark;
          font-size: 14px;
        }

        @include mobile {
          width: calc(100% - 65px) !important;
        }
      }

      .multiselect__single {
        padding: 0;
        margin: 0;
        margin-top: 2px !important;
        min-height: 18px;
        line-height: 1;
        border-radius: 0;
        font-size: 16px !important;
        vertical-align: baseline;
        text-wrap: nowrap;
        overflow: hidden;
      }

      .multiselect__placeholder {
        margin-bottom: 0;
        padding-top: 0;
        font-size: 16px !important;
        color: #374044;

        @include mobile {
          display: none;
        }
      }

      .multiselect__tags {
        line-height: 1.4;
        border-color: $input-border-color;
        padding: 8px 34px 0 38px;
        min-width: 355px;
        border-radius: 8px !important;

        @include no-box-shadow;
        @include transition($primary-transition);

        @include mobile {
          min-width: auto;
          padding-right: 20px;
        }

        &:hover {
          border-color: $input-border-hover;
        }

        &:focus,
        &:visited {
          border-color: $input-border-focus;
        }

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .multiselect__option {
        color: $color-grey-darkest;
        font-size: 14px;

        &--highlight {
          background-color: $color-grey-lightest;
        }
      }

      .multiselect__spinner {
        right: 30px !important;
        border-top-color: $color-primary !important;
      }

      .multiselect__spinner::after,
      .multiselect__spinner::before {
        z-index: 1;
      }

      .multiselect__clear {
        position: absolute;
        right: 2px;
        height: 40px;
        width: 40px;
        display: block;
        cursor: pointer;
        z-index: 2;
        opacity: 0;
        visibility: hidden;

        @include transition($primary-transition);

        &.active {
          opacity: 1;
          visibility: visible;
        }

        .t-icon {
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
        }
      }
    }
  }

  .multiselect--active {
    display: block;

    & /deep/ {
      .multiselect__input {
        width: 100% !important;
        font-size: 16px;
        margin-top: 2px;
      }
    }
  }

  .t-input-group-with-icon {
    .t-input-icon-group {
      .t-input-icon-left {
        z-index: 51;
      }
    }
  }
}
