// Text
@mixin ellipsis($clamp) {
  max-width: 100%;
  word-wrap: normal;
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin typography-text-normal {
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
