/******************************************
  Some tips for creating layouts :
  - Add `l` prefix before the name of layouts
  - Or Just copy paste this structure of styling code into your new layouts styling file
  - Dont forget to register your layouts into `_layouts.scss`
******************************************/
/** This Place For Layouts Home **/
.#{$namespace} {
  & .l-home {
    & .home {
      &-wrapper-main {
        position: relative;

        &--is-wlp {
          &.gap-footer {
            padding-bottom: 32px;
          }
        }

        & .sections-order {
          position: relative;

          &__item {
            margin-top: 32px;

            &:first-child {
              padding-top: 32px;
            }

            &:last-child {
              padding-bottom: 64px;
            }
          }
        }
      }

      &-wrapper-shortcut-panel {
        position: relative;
        min-height: 120px;

        & .shortcut-panel {
          &__gap {
            display: none;
            height: 70px;
            transition: all 0.5s ease-in-out;

            @media (min-width: 768px) {
              display: block;
            }
          }
        }
      }
    }

    & .c-banner-promotion {
      margin-top: 64px;
    }

    & .t-hero-main {
      &__overlay {
        height: 100%;
        left: 0;
        opacity: 0.25;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 1;
      }

      &-background {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        overflow: hidden;
      }

      &-content {
        height: 320px;

        @include media-up(md) {
          height: 440px;
        }

        & .t-heading-1 {
          font-size: 28px;

          @include media-up(md) {
            font-size: 32px;
          }
        }

        & .t-body-text {
          @include media-up(md) {
            font-size: 15px;
          }
        }

        & .t-display-table-cell {
          & .t-text-align-center {
            @include media-up(md) {
              padding-bottom: 50px;
            }
          }
        }
      }
    }

    & .hero-carousel {
      &__content {
        height: 440px;
        max-width: 580px;

        @include media-up(lg) {
          max-width: 640px;
        }

        @include media-up(xl) {
          height: 560px;
        }
      }
    }
  }
}

/* ************************************ */
