/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Trending Destinations **/
.#{$namespace} {
  & .c-home-trending-destinations {
    &:last-child {
      padding-bottom: 64px;
    }

    & .c-card {
      & .#{$prefix} {
        &-card-title {
          height: 320px;
          max-height: 320px;
        }
      }

      & .t-card-title-content.bottom {
        top: auto;
        bottom: 0;
        left: 0;

        @include transform(none !important);
      }

      & .description {
        font-size: 14px;
      }

      & .title {
        font-size: 24px;
      }
    }
  }
}

/* ************************************ */
