@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-flex.scss";

$default-color: #F1F7F9 !default;

.c-banner-multipurpose {
  background-color: $default-color;

  &.banner {
    &-position {
      &-top {
        position: relative;
      }

      &-bottom {
        order: 1;
      }
    }
  }

  .banner {
    &-wrapper {
      @include flex-init();
      @include align-items(center);
      @include justify-content(space-between);

      &-content {
        @include flex-init();
        @include align-items(center);

        @media screen and (max-width: 1024px) {
          @include flex-direction(column);
          @include align-items(flex-start);
        }
      }
    }

    &-alignment {
      &-left {
        @include justify-content(flex-start);
      }

      &-center {
        @include justify-content(center);
      }
    }

    &-action,
    &-icon {
      @include flex-init();
    }

    &-dissmissable {
      right: 24px;
    }

    @media screen and (max-width: 1024px) {
      &-content {
        margin-right: 10px;
      }

      &-icon {
        margin-right: 10px;
      }
    }
  }
}
