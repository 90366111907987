@keyframes card-skeleton {
  0% {
    background-position: -600px 0;
  }

  100% {
    background-position: 600px 0;
  }
}

.travlr-ui {
  .t-card-skeleton {
    pointer-events: inherit;

    & .t-card-skeleton-image {
      animation: card-skeleton 1500ms linear infinite forwards;
      background-size: 1100px 104px;
    }

    & .t-card-skeleton-text {
      animation: card-skeleton 1500ms linear infinite forwards;
      background-size: 1100px 104px;
      height: 100%;
    }
  }
}
