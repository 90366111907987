/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Find Inspiration Personalized **/
.#{$namespace} {
  & .c-home-find-inspiration-personalized {
    padding-top: 32px;

    &:last-child {
      padding-bottom: 64px;
    }

    & .home-find-inspiration-personalized {
      &__container {
        & .c-card-inspiration-shortlist {
          &.column-item {
            margin-bottom: 8px;
          }

          & .t-card-title-content {
            &.bottom {
              top: 65%;
            }
          }

          & .no-margin {
            margin: 0;
          }

          & .is-height {
            height: 240px !important;
          }

          & .c-card-content {
            display: table;
            width: 100%;
            height: 50px;

            & .left-content {
              display: table-cell;
              width: 70%;

              p {
                font-size: 20px;
                line-height: 1.4;
              }
            }

            & .right-content {
              display: table-cell;
              width: 30%;
              text-align: right;
              vertical-align: middle;

              a {
                border: 1px white solid;
                font-size: 16px;
                font-weight: bold;
                padding: 8px;
                border-radius: 4px;
              }
            }
          }
        }
      }

      &__panel {
        & .t-card-title-large {
          min-height: 242px;
          max-height: 242px;
        }

        & .t-card-title-content {
          &.title-wrapper {
            top: auto !important;
            bottom: 0 !important;
            padding: 24px !important;

            @include transform(translate3d(-50%, 0%, 0) !important);
          }
        }

        & .link {
          color: white;

          &:hover {
            background: $color-primary !important;
            border-color: $color-primary;
          }

          &.mobile {
            display: none;
          }

          & .t-icon {
            top: 3px;
            position: relative;
          }
        }
      }
    }
  }
}

/* ************************************ */
