@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.c-card-events {
  display: inline-block;

  & .event-date {
    color: $color-grey-dark;
    font-size: 14px;
  }

  & .t-card-content-footer {
    position: relative;
    height: 60px;

    &::before,
    &::after {
      content: '';
      display: table;
    }

    &::after {
      clear: both;
    }

    &-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &-left {
      float: left;
      height: 100%;
    }

    &-right {
      float: right;
    }
  }
}
