@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "~/assets/scss/variables.scss";

.c-datepicker-range {
  margin-bottom: 12px;

  .t-date-range-picker {
    &-wrapper {
      inset: 0;
    }

    &-trigger {
      color: #606266 !important;
      z-index: 10;
      padding: 0 12px !important;
    }
  }

  .el {
    &-range-separator {
      width: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-date-editor {
      .el-range-input {
        text-align: left;
        font-size: 16px;

        &:first-child {
          border-right: 1px solid #DDD;
        }
      }

      .el-range__close-icon {
        display: none;
      }
    }

    &-input__inner {
      border: 0;
      border-radius: 0;
      line-height: 38px;
      padding-right: 12px !important;
      padding-left: 0;
      height: 38px;
      font-size: 16px;

      @include placeholder {
        color: $color-grey-dark;
        font-size: 16px;
      }
    }

    &-input__suffix {
      .el-input__icon {
        opacity: 0;
        visibility: hidden;

        &.el-icon-circle-close {
          top: 6px !important;
          opacity: 1;
          visibility: visible;

          @include transform(translateY(0) !important);
          @include transition($primary-transition);
        }
      }
    }

    &-range__icon,
    &-icon-date {
      display: none !important;
    }

    &-icon-circle-close {
      color: $color-grey-dark !important;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 20px !important;
      height: auto;
      right: 0;

      &::before {
        content: '\ea53';

        @include icon-default;
      }
    }
  }

  [class^="el-icon-"],
  [class*=" el-icon-"] {
    @include icon-default;
  }
}
