// Responsiveness
@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $screen-xs) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $screen-md) {
    @content;
  }
}

@mixin media-up($point) {
  @if $point == xs {
    @media (min-width: $trv-screen-xs) {
      @content;
    }
  }

  @else if $point == sm {
    @media (min-width: $trv-screen-sm) {
      @content;
    }
  }

  @else if $point == md {
    @media (min-width: $trv-screen-md) {
      @content;
    }
  }

  @else if $point == lg {
    @media (min-width: $trv-screen-lg) {
      @content;
    }
  }

  @else if $point == xl {
    @media (min-width: $trv-screen-xl) {
      @content;
    }
  }
}

@mixin media-down($point) {
  @if $point == xs {
    @media (max-width: $trv-screen-xs-max) {
      @content;
    }
  }

  @else if $point == sm {
    @media (max-width: $trv-screen-sm-max) {
      @content;
    }
  }

  @else if $point == md {
    @media (max-width: $trv-screen-md-max) {
      @content;
    }
  }

  @else if $point == lg {
    @media (max-width: $trv-screen-lg-max) {
      @content;
    }
  }
}
