@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  .c-home-search {
    z-index: 10;

    @include media-down(sm) {
      .t-container {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    @include media-up(md) {
      margin-top: -82px;
    }

    &__important {
      z-index: 999999;
    }
  }

  .home-search {
    background-color: #023850;

    // light OTA
    &.light-ota {
      background-color: #FFF !important;

      .multiselect__tags,
      .t-form .t-input-group .t-input-text,
      .t-form .t-input-group .t-input-text-tinted,
      .t-form .t-input-group .t-input-textarea,
      .t-form .t-input-group .t-input-textarea-tinted,
      .home-search__tab {
        border-color: #D1D5DB !important;
      }

      .accommodation-search .multiselect .caret,
      .accommodation-search .guest-dropdown-counters-container .guest-count-btn {
        border: 1px solid #D1D5DB;
      }

      .home-search__label .t-icon,
      .flight-type-item-button.t-color-white {
        color: $color-grey-darkest !important;
      }
    }

    &.dark-ota {
      position: relative;
    }

    @include media-up(md) {
      border-radius: $radius;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    }

    &__label {
      font-size: 12px;

      .t-icon {
        color: #06B4FF;
        font-size: 20px;
        position: relative;
        top: -1px;
      }

      .c-image {
        width: auto;
      }
    }

    &__tab {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      max-height: 56px;

      .search-tab {
        overflow: hidden;
        width: 100%;

        @include media-up(md) {
          border-top-left-radius: 4px;
        }

        @include media-up(lg) {
          width: 700px;
        }

        &__button {
          border: none;
          border-radius: 0;
          cursor: pointer;
          color: $color-white;
          display: block;
          font-size: 14px;
          font-weight: 600;
          height: auto;
          line-height: 38px;
          padding: 6px 16px;
          white-space: nowrap;

          @include media-up(md) {
            padding: 8px 24px;
          }

          & .t-icon {
            min-width: 24px;
          }
        }
      }
    }

    &__form {
      padding: 24px 16px;

      @include media-up(md) {
        padding-left: 24px;
        padding-right: 24px;
      }

      & .t-input-label,
      .input-label {
        // force disable all input label
        display: none !important;
      }

      & .t-form {
        & .t-input-group {
          margin-bottom: 12px;

          @include media-up(md) {
            // replce default style
            margin-bottom: 0 !important;

            & .t-departure-input,
            .t-return-input,
            .t-one-way-input {
              margin-bottom: 12px;
            }
          }
        }
      }

      & .c-flight-search-form {
        & .flight-type {
          margin-bottom: 18px;
          margin-top: 0;
        }

        & .flight-search-form {
          & .t-input-group {
            & .t-input-label {
              // force disable all input label
              display: none !important;
            }
          }

          > div > .t-row {
            margin-left: -4px;
            margin-right: -4px;

            > [class*="t-col-"] {
              padding-left: 4px;
              padding-right: 4px;
            }
          }

          .t-btn-primary {
            @include media-up(md) {
              margin-top: 12px;
            }

            @include media-up(lg) {
              margin-top: 0;
            }

            @include media-up(xl) {
              width: 100%;
            }
          }
        }
      }

      & .accommodation-search {
        > .t-row {
          margin-left: -4px;
          margin-right: -4px;

          > [class*="t-col-"] {
            padding-left: 4px;
            padding-right: 4px;
          }
        }

        & .t-m-b-12 {
          @include media-up(lg) {
            margin-bottom: 0;
          }
        }
      }

      & .deal-top-filter {
        // replace theme color
        background-color: transparent !important;
        padding: 0;

        & .t-container {
          padding-left: 0;
          padding-right: 0;
        }

        & .t-top-form {
          display: block;
        }

        &__nav {
          display: none;
        }
      }

      & .c-federated-search-top-filter {
        // replace default style
        padding-top: 0 !important;

        & .t-col-lg-9 {
          width: 100%;
        }

        & .federated-search-alert-tag {
          display: none;
        }

        & .label-keyword-filter {
          display: none;
        }

        & .t-form {
          // replace theme color
          background-color: transparent !important;
          padding-bottom: 0 !important;
          padding-top: 0 !important;
        }

        & .federated-search-chechbox-filter {
          & .t-col-lg-3 {
            & .t-input-group {
              @include media-up(lg) {
                // replace default style
                margin-bottom: 12px !important;
              }
            }
          }
        }
      }
    }

    .bne-icon {
      height: 24px;

      &.active {
        path {
          fill: #FFF;
        }
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
