@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-flex.scss";

// NOTE: override UIkit fix truncated placeholder
.t-form.c-federated-search-suggestion-form .t-input-group-with-icon .federated-search-input.t-input-text-tinted-left {
  padding-right: 10px !important;
}

@include mobile {
  .c-federated-search {
    &-suggestion-form {
      &.active {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding-right: 0;
        z-index: 99;
        overflow: auto;
        background-color: #FFF;

        .c-federated-search-header {
          @include flex-init();
          @include align-items(center);
          @include justify-content(flex-start);
          padding: 16px;
        }

        .c-federated-search-header,
        .federated-search-status {
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
        }

        .federated-search-status {
          display: block;
        }

        .t-navbar-top-right-search-mobile {
          display: none !important;
        }

        .t-navbar-top-right-search-suggestion-form {
          display: block !important;
          max-width: none;
        }

        .federated-search-input {
          height: 48px;
        }
      }

      .federated-search-status {
        display: none;
      }
    }
  }
}

.c-federated-search-suggestion {
  padding-bottom: 0 !important;

  @include mobile {
    padding: 0;
  }

  @include tablet {
    right: 0;
  }

  .federated-search-result-item {
    &-icon {
      line-height: 0;
      width: 32px;
    }

    &-all-result {
      border-top: 1px solid $color-grey-lighter;
    }
  }

  .t-navbar-search-dropdown-list-item-link {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
