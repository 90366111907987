@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{travlr-ui} {
  & .hero-carousel {
    overflow: hidden;

    &__single {
      &.hero-carousel {
        & .flickity-prev-next-button {
          display: none;
        }

        & .flickity-page-dots {
          display: none;
        }
      }
    }

    &__overlay {
      background-color: rgba($color-grey-darkest, 0.2);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &__content {
      height: 440px;
      margin-left: auto;
      margin-right: auto;
      max-width: 580px;

      @include media-up(lg) {
        max-width: 640px;
      }

      @include media-up(xl) {
        height: 560px;
      }
    }

    &__background {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 440px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      @include media-up(xl) {
        height: 560px;
      }
    }

    &__separator {
      display: block;
      margin-bottom: 8px;
      margin-top: 8px;

      @include media-up(sm) {
        display: inline-block;
        margin: 0 8px;
      }
    }

    &__heading {
      font-size: 28px;
      opacity: 0;
      position: relative;
      top: -20px;
      transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.6s;

      @include media-up(md) {
        font-size: 32px;
      }
    }

    &__text {
      opacity: 0;
      position: relative;
      top: -20px;
      transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s;

      @include media-up(md) {
        font-size: 15px;
      }
    }

    &__button {
      display: table;
      margin-left: auto;
      margin-right: auto;
      opacity: 0;
      position: relative;
      top: 40px;
      transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.7s;
      visibility: hidden;

      @include media-up(md) {
        display: block;
        padding-bottom: 50px;
      }

      & .t-btn {
        width: 100%;

        @include media-up(md) {
          width: auto;
        }
      }
    }

    & .flickity-viewport {
      height: 440px !important;
      overflow: hidden;

      @include media-up(xl) {
        height: 560px !important;
      }
    }

    & .carousel-item {
      &.is-selected {
        & .hero-carousel__button {
          opacity: 1;
          top: 0;
          visibility: visible;
        }

        & .hero-carousel__heading,
        & .hero-carousel__text {
          top: 0;
          opacity: 1;
        }
      }
    }

    & .flickity-prev-next-button {
      display: none;
      background-color: rgba(55, 64, 68, 0.6);
      color: $color-white;
      height: 48px;
      width: 48px;

      @include media-up(md) {
        display: block;
      }

      &:hover {
        background-color: rgba(55, 64, 68, 0.8);
      }

      &.previous {
        @include media-up(lg) {
          left: 64px;
        }
      }

      &.next {
        @include media-up(lg) {
          right: 64px;
        }
      }

      & .flickity-button-icon {
        height: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
      }
    }

    & .flickity-page-dots {
      bottom: 16px;

      & .dot {
        opacity: 1;
        border: 1px solid $color-white;
        height: 8px;
        width: 8px;

        &.is-selected {
          border: none;
          height: 9px;
          width: 9px;
        }
      }
    }
  }
}
