@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-flex.scss";

.deal-top-filter {
  z-index: 99;

  @include media-up(md) {
    height: auto;
    padding-bottom: 24px;
    padding-top: 24px;
    position: relative;
    top: auto;
  }

  & .t-form-inline {
    display: none;

    @include media-up(md) {
      display: flex !important;

      @include align-items(center);
    }
  }

  .disabled-dropdown-opt {
    color: rgba($color-grey-darkest, 0.4);
    cursor: default;

    &:hover {
      color: rgba($color-grey-darkest, 0.4);
    }
  }

  & .t-form {
    &--disabled {
      & .t-btn {
        cursor: not-allowed;
        position: relative;

        &::before {
          content: "";
          background-color: rgba($color-white, 0.5);
          display: block;
          left: 0;
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
        }
      }
    }

    & .form-input {
      &__field {
        width: 100%;
      }
    }
  }

  &__group {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    @include media-up(md) {
      margin-right: 8px;
      margin-bottom: 0;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }
    }

    & .t-btn-primary {
      width: 100%;

      @include media-up(md) {
        width: auto;
      }

      @include media-up(lg) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  &__search {
    width: 100%;

    @include media-up(md) {
      width: 355px;
    }
  }

  & .form-input--deal {
    & .form-input {
      &__field {
        @include media-up(md) {
          width: 326px;
        }

        @include media-up(lg) {
          width: 352px;
        }
      }
    }
  }

  & .form-input--destination {
    & .form-input {
      &__field {
        @include media-up(md) {
          width: 200px;
        }

        @include media-up(lg) {
          width: 240px;
        }
      }
    }
  }

  &__nav {
    left: 0;
    position: fixed;
    right: 0;
    top: auto;
    height: 48px;
    z-index: 10;

    @include media-up(md) {
      display: none;
    }

    & .t-ellipsis {
      color: $color-white;
      font-size: 14px;
      width: 75%;
    }

    & .btn {
      color: $color-white;
      height: 48px;
      padding: 0;
    }

    & .nav {
      &__right {
        text-align: right;
      }
    }
  }
}
