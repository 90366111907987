.#{$namespace} {
  .dropdown {
    margin-top: 8px;
    opacity: 0;
    position: absolute;
    top: 100%;
    transition: all 0.1s ease-in-out;
    visibility: hidden;
    width: 100%;
    z-index: 100;

    &::after {
      content: "";
      background-image: url("https://s3-ap-southeast-2.amazonaws.com/cdn.static.travlr.com/production/icons/triangle.png");
      background-position: center;
      background-repeat: no-repeat;
      left: 10px;
      position: absolute;
      top: -8px;
      height: 13px;
      width: 17px;
    }

    &__label {
      vertical-align: middle;
    }

    &__inner {
      background-color: $color-white;
      border: 1px solid $color-grey-light;
      border-radius: $radius;
      box-shadow: 0 2px 4px rgba($color-black, 0.2);
      max-height: 300px;
      overflow: auto;
      padding-bottom: 5px;
      padding-top: 5px;
      -webkit-overflow-scrolling: touch;
    }

    &__button {
      cursor: pointer;
      padding: 11px 12px;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: $color-grey-lightest;
      }
    }

    &__item {
      &:first-child {
        .dropdown__button {
          &:hover {
            border-top-color: transparent;
          }
        }
      }

      &:last-child {
        .dropdown__button {
          &:hover {
            border-bottom-color: transparent;
          }
        }
      }
    }
  }
}
