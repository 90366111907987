.#{$travlr-ui} {

  .#{$travlr-prefix} {

    // Columns
    &columns {
      @include clearfix;

      [class*="t-col-"] {
        float: left;
      }

      &-with-gap {
        margin-right: -$container-gap;
        margin-left: -$container-gap;

        [class*="t-col-"] {
          padding-right: $container-gap;
          padding-left: $container-gap;
        }
      }
    }

    // Column
    @for $i from 1 through 12 {
      &col-#{$i} {
        width: 100% / (12/$i);
      }
    }

    @include mobile {
      @for $i from 1 through 12 {
        &col-mobile-#{$i} {
          width: 100% / (12/$i);
        }
      }
    }

    @include tablet {
      @for $i from 1 through 12 {
        &col-tab-#{$i} {
          width: 100% / (12/$i);
        }
      }
    }

    // Offset
    @for $i from 1 through 12 {
      &offset-#{$i} {
        margin-left: 100% / (12/$i) !important;
      }
    }
    
    @include mobile {
      @for $i from 1 through 12 {
        &mobile-offset-#{$i} {
          margin-left: 100% / (12/$i) !important;
        }
      }
    }

    &no-offset {
      margin-left: 0 !important;
    }

    @include mobile {
      &no-offset-mobile {
        margin-left: 0 !important;
      }
    }

    // New grid system
    // Will remove class migration after finishing migrate all class grid systems. Just for wrapping new class core implementation and not creating new prefix
    &#{$travlr-class-migration} {
      & [class*="t-col-"] {
        float: left;
        padding-left: ($grid-gutter-width);
        padding-right: ($grid-gutter-width);
        width: 100%;
      }

      @include loopColumn(xs);

      @include media-up(sm) {
        @include loopColumn(sm);
      }

      @include media-up(md) {
        @include loopColumn(md);
      }

      @include media-up(lg) {
        @include loopColumn(lg);
      }

      @include media-up(xl) {
        @include loopColumn(xl);
      }

      // Row grid
      & .#{$travlr-prefix} {
        &row {
          margin-left: -($grid-gutter-width);
          margin-right: -($grid-gutter-width);

          @include clearfix();
        }
      }
    }
  }
}
