/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-home-holiday {
      & .home-holiday {
        &__container {
          & .c-card {
            & .t-card-title {
              min-height: 210px;
              max-height: 210px;

              & .title {
                font-size: 20px;
              }

              & .description {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
