/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Find Inspiration **/
.#{$namespace} {
  & .c-home-find-inspiration {
    &:last-child {
      padding-bottom: 64px;
    }

    & .t-card-title-large {
      &.wrapper {
        margin-bottom: 0;
      }

      & .t-card-title-content {
        &.fi-card {
          padding: 0 24px;

          &.v-align-bottom {
            top: auto;
            bottom: 24px;
            left: 0;

            @include transform(none !important);
          }
        }
      }
    }

    & .c-card {
      & .t-card-overlay {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7525385154061625) 0%, rgba(0, 0, 0, 0.2455357142857143) 35%, rgba(0, 0, 0, 0) 60%);
      }

      & .#{$prefix} {
        &-card-title {
          height: 320px;
          max-height: 320px;
        }
      }
    }
  }
}

/* ************************************ */
