/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Find Inspiration **/
.#{$namespace} {
  & .c-home-articles {
    &:last-child {
      padding-bottom: 64px;
    }

    & .c-card {
      & .#{$prefix} {
        &-card-title {
          min-height: 270px;
          max-height: 270px;
          height: 270px;

          &.title {
            &--bottom {
              & .#{$prefix} {
                &-card-title {
                  &-content {
                    top: unset;
                    height: 100%;
                    bottom: unset;
                    position: relative !important;
                    left: unset;
                    right: unset;
                    transform: unset !important;
                    padding: 0;

                    & .title {
                      position: absolute;
                      bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ************************************ */
