.product-price {
  .additional-price-info {
    color: #6B7280;
    font-size: 14px;
    font-weight: normal !important;

    .tooltip {
      margin-left: 0 !important;
    }
  }
}
