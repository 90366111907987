// Placeholders
@mixin placeholder {
  $placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input';

  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
