@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-flight-passanger-counter {
  .el-input-number {
    span {
      line-height: 38px;
    }
  }

  .t-input-number-dropdown {
    @include mobile {
      min-width: auto !important;
    }
  }
}
