.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &badge {
      text-transform: uppercase;
      color: white;
      font-size: 8px;
      text-align: center;
      background-color: $color-primary;
      padding: 2px;
      border-radius: 2px;
      display: inline;

      // Type
      &-circle {
        border-radius: 100% !important;
        width: 16px;
        height: 16px;
        line-height: 16px;
        display: inline-block;
        padding: 0 !important;
      }

      // Position
      &-hang-top {
        &-text {
          vertical-align: super;
          top: -5px;
        }

        &-icon {
          vertical-align: super;
          left: -10px;
          top: -10px;
        }
      }

      // Background
      &-danger {
        background-color: $color-danger !important;
      }

      &-warning {
        background-color: $color-warning !important;
      }

      &-success {
        background-color: $color-success !important;
      }
    }

    &badge-text {
      display: inline-block;
      border: 1px solid $color-secondary;
      border-radius: 16px;
      padding: 4px 16px;
      color: $color-secondary;
      font-weight: $weight-semibold;
      font-size: $size-text-body;
      line-height: $line-height-text-callout;
      margin-right: 4px;
      margin-bottom: 8px;

      @include transition($primary-transition);
      @include typography-text-normal;

      &:hover {
        background-color: $color-secondary;
        color: white;
      }
    }

    &badge-alt {
      display: inline-block;
      border-radius: $radius;
      padding: 2px 16px;
      color: $color-secondary;
      font-weight: $weight-regular;
      font-size: $size-text-caption;
      line-height: $line-height-text-callout;
      margin-right: 8px;
      margin-bottom: 8px;

      @include transition($primary-transition);
      @include typography-text-normal;

      &-icon {
        margin-right: 12px;
        position: relative;
        top: 2px;
        font-size: 20px;
      }

      &-content {
        position: relative;
        top: -5px;
      }

      &-text {
        line-height: $line-height-text-4;
      }

      // Background
      &-default {
        color: $color-grey-dark;
        background-color: rgba($color-grey-med, 0.2) !important;
      }

      &-danger {
        color: $color-danger;
        background-color: rgba($color-danger, 0.2) !important;
      }

      &-warning {
        color: #FFF;
        background-color: rgba($color-warning, 0.2) !important;
      }

      &-success {
        color: $color-grey-dark;
        background-color: rgba($color-success, 0.2) !important;
      }
    }
  }
}
