@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-card {
  display: inline-block;

  .t-card-content-footer-left-location {
    display: block;
    min-height: 24px;
  }

  .t-strike {
    text-decoration-line: line-through;
  }

  .clock-icon {
    font-size: 16px;

    @include transform(translateY(-1px));
  }

  .t-card-content-footer {
    min-height: 150px;
  }
}
