@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-icon.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-opacity.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-border-radius.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-miscellaneous.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";
@import "@/Library/UiKit/src/scss/uikit/elements/forms/_forms.scss";
@import "~/assets/scss/variables.scss";

.c-datepicker {
  &.auto-width {
    .t-input-global-datepicker {
      min-width: 0 !important;
      max-width: auto !important;
    }
  }

  @include tablet {
    width: 100%;

    .t-date-picker {
      width: 100%;
    }

    .t-input-global-datepicker {
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  .t-date-picker {
    .el-input__inner {
      border: 0;
      border-radius: $radius;
      line-height: 38px;
      padding-right: 0;
      padding-left: 0;
      height: 38px;
      font-size: 16px;

      @include placeholder {
        color: $color-grey-dark;
        font-size: 16px;
      }
    }

    .el-input__suffix {
      & .el-input__icon {
        opacity: 0;
        visibility: hidden;

        &.el-icon-circle-close {
          top: 6px !important;
          opacity: 1;
          visibility: visible;

          @include transform(translateY(0) !important);
          @include transition($primary-transition);
        }
      }
    }

    .el-icon-date {
      color: $color-grey-dark !important;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 20px !important;
      height: auto;

      &::before {
        content: '\ea3e';
      }
    }

    .el-icon-circle-close {
      color: $color-grey-dark !important;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 20px !important;
      height: auto;
      right: 0;

      &::before {
        content: '\ea53';

        @include icon-default;
      }
    }

    [class^="el-icon-"],
    [class*=" el-icon-"] {
      @include icon-default;
    }

    .t-departure-input {
      border-radius: 4px 0 0 4px !important;
      border-left-width: 1px !important;
    }

    .t-return-input {
      border-radius: 0 4px 4px 0 !important;
      border-left-width: 0 !important;
    }
  }
}
