/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Section Title Button **/
.#{$namespace} {
  @media screen and (min-width: 1024px) {
    .section-button-responsive {
      display: none !important;
    }
  }

  // NOTE: override UIkit to support multiline text on cta button
  .btn-cta-checkout {
    height: auto;
    line-height: 1.2;
    padding: 12px 20px !important;

    span {
      font-size: 16px !important;
      vertical-align: middle;
    }

    .t-icon {
      position: static;
    }
  }
}

/* ************************************ */
