@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.c-ajax-auto-complete {
  & .ajax-auto-complete {
    &-popup {
      top: 100%;
      left: 0;
      opacity: 0;
      width: 350px;
      max-height: 220px;
      overflow: auto;
      visibility: hidden;
      pointer-events: none;
      z-index: 9;

      @include transition($primary-transition);

      @include mobile {
        max-width: 100%;
      }

      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: fill;
      }
    }

    &-input {
      padding-right: 36px !important;

      &-loader {
        top: 10px;
        right: 8px;
      }
    }

    &-result {
      cursor: pointer;

      @include transition($primary-transition);

      &:hover {
        background-color: $color-grey-lightest;
      }
    }

    &-suggestion-icon {
      @include transform(rotate(45deg));
    }
  }
}
