.#{$travlr-ui} {

  .#{$travlr-prefix} {
    &form {
      &-inline {
        & .#{$travlr-prefix} {
          &input-group {
            display: inline-block !important;
          }
        }
      }

      & .#{$travlr-prefix} {

        // Input Group Custom With Button
        &input-group-with-button {
          display: table;

          @include mobile {
            width: 100%;
          }

          .#{$travlr-prefix} {
            &input {
              &-group {
                display: table-cell;
                vertical-align: middle;
              }

              &-text,
              &-text-tinted {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
              }
            }

            &btn {
              border-top-left-radius: 0 !important;
              border-bottom-left-radius: 0 !important;
              height: $form-height-medium;
            }
          }
        }

        // Input Group With Button
        &input-group {
          margin-bottom: 12px;

          @include mobile {
            width: 100%;
            margin-right: 0 !important;
          }

          // Success & Error
          &.has-error {
            & .#{$travlr-prefix}input {
              &-text,
              &-text-tinted,
              &-textarea,
              &-textarea-tinted {
                border-color: $color-danger !important;
              }

              &-label {
                color: $color-danger !important;
              }
            }
          }

          &.has-success {
            & .#{$travlr-prefix}input {
              &-text,
              &-text-tinted,
              &-textarea,
              &-textarea-tinted {
                border-color: $color-success !important;
              }

              &-label {
                color: $color-success !important;
              }
            }
          }

          & .#{$travlr-prefix}input {

            // Label
            &-label {
              display: block;
              margin-bottom: 8px;
              font-size: 14px;
              font-weight: 600;

              @include typography-text-normal;
            }

            // Message Label
            &-message {
              font-size: 12px;

              @include typography-text-normal;
              line-height: 1.33;
              letter-spacing: 0.2px;
              color: $color-grey-darkest;
              margin-top: 8px;
            }

            // Validation
            &-validation {
              font-size: 12px;

              @include typography-text-normal;
              line-height: 1.33;
              letter-spacing: 0.2px;
              color: $color-danger;
              margin-top: 8px;
            }

            // Text & Textarea
            &-text,
            &-text-tinted,
            &-textarea,
            &-textarea-tinted {
              width: 100%;
              border-radius: $radius;
              border: solid 1px $input-border-color;
              cursor: pointer;
              padding: 0 34px 0 12px;
              outline: 0;
              color: $color-grey-darkest;
              font-size: 16px;
              -webkit-appearance: none;

              @include placeholder {
                color: $color-grey-dark;
                font-size: 16px;
              }

              &:hover {
                border-color: $input-border-hover;
              }

              &:focus,
              &:visited {
                border-color: $input-border-focus;
              }

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }

            &-text,
            &-text-tinted {
              height: $form-height-medium;

              &-small {
                height: $form-height-small;
              }

              &-large {
                height: $form-height-large;
              }
            }

            &-text,
            &-textarea {
              background-color: white;
            }

            &-text-tinted,
            &-textarea-tinted {
              background-color: $color-grey-lightest;
            }

            &-textarea,
            &-textarea-tinted {
              padding: 12px;
            }

            &-multiselect {
              &-item {
                color: $color-grey-darkest;
                font-size: 14px;
                height: 14px;
                line-height: 1.4;

                &-value {
                  height: 18px;
                  overflow: hidden;
                  line-height: 1.4;
                }

                & .t-icon {
                  color: $color-grey-dark;
                  font-size: 20px;
                }
              }
            }

            &-global-search {
              min-width: 355px;

              @include mobile {
                min-width: auto;
                max-width: 100%;
              }
            }

            &-global-datepicker {
              min-width: 245px;
              max-width: 245px;

              @include mobile {
                min-width: auto;
                max-width: 100%;
              }
            }

            // Select
            &-select {
              position: relative;
              display: inline-block;
              width: 100%;
              border: 1px solid $input-border-color;
              border-radius: $radius;
              -webkit-appearance: none;
              height: $form-height-medium;

              @include transition($primary-transition);

              &:focus,
              &:hover {
                border-color: $input-border-hover;
              }

              &.has-error {
                border-color: $color-danger !important;
              }

              &-inverted {
                border: 1px solid #FFF;
                background: #FFF !important;

                &:focus,
                &:hover {
                  border-color: #FFF !important;
                }

                select {
                  background: #FFF !important;
                }
              }

              select {
                display: inline-block;
                width: 100%;
                cursor: pointer;
                padding: 10px 30px 10px 15px;
                background: transparent;
                border: 0;
                outline: 0;
                color: $color-grey-darkest;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;

                &::-ms-expand {
                  display: none;
                }

                &:disabled {
                  opacity: 0.5;
                  pointer-events: none;
                }
              }

              &-arrow {
                position: absolute;
                top: 8px;
                right: 32px;
                width: 0;
                height: 0;
                pointer-events: none;

                &::after {
                  content: '\EA05';

                  @include icon-default;
                  color: $color-grey-med;
                }
              }

              &-small {
                height: $form-height-small;
              }

              &-large {
                height: $form-height-large;

                & select {
                  padding: 14px 15px;
                }

                & .t-input-select-arrow {
                  top: 12px;
                }
              }

              &-tinted {
                background-color: $color-grey-lightest;
              }

              &-disabled {
                &:hover {
                  border: 1px solid #CCC;
                  cursor: no-drop;
                }
              }
            }

            // Control Checkbox and Radio
            &-control {
              display: block;
              position: relative;
              padding-left: 30px;
              margin-bottom: 0;
              cursor: pointer;
              color: $color-grey-darkest;
              font-weight: $weight-semibold;
              font-size: $size-text-body;
              line-height: 22px;

              @include typography-text-normal;
              @include transition($primary-transition);

              input {
                position: absolute;
                z-index: -1;
                opacity: 0;

                &:checked {
                  & ~ .t-input-control-indicator {
                    border-color: $color-primary;
                    background: $color-primary;

                    &::after {
                      display: block;
                    }
                  }

                  &:focus {
                    & ~ .t-input-control-indicator {
                      background: $color-primary;
                    }
                  }
                }

                &:disabled {
                  & ~ .t-input-control-indicator {
                    background: #E6E6E6;
                    opacity: 0.6;
                    pointer-events: none;
                  }
                }
              }

              &:hover {
                color: $color-primary;

                input {
                  & ~ .t-input-control-indicator {
                    border-color: $color-primary;
                  }

                  &:not([disabled]) {
                    &:checked {
                      & ~ .t-input-control-indicator {
                        background: $color-primary;
                      }
                    }
                  }
                }
              }
            }

            &-control-indicator {
              position: absolute;
              top: 2px;
              left: 0;
              height: 18px;
              width: 18px;
              background-color: transparent;
              border: 2px solid $color-grey-dark;

              @include transition($primary-transition);

              &::after {
                content: '';
                position: absolute;
                display: none;
              }
            }

            // TODO: sometime the radio button not clickable
            &-control-radio {
              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }

              & .t-input-control-indicator {
                border-radius: 50%;
                background: transparent !important;

                &::after {
                  left: 2px;
                  top: 2px;
                  height: 10px;
                  width: 10px;
                  border-radius: 50%;
                  background: $color-primary;

                  @include transition($primary-transition);
                }
              }

              input {
                &:disabled {
                  & ~ .t-input-control-indicator {
                    &::after {
                      background: #7B7B7B;
                    }
                  }
                }
              }
            }

            &-control-checkbox {
              &.disabled {
                opacity: 0.5;
                pointer-events: none;
              }

              & .t-input-control-indicator {
                &::after {
                  @include icon-default;
                  display: none;
                  content: '\ea4d';
                  left: -2px;
                  top: -2px;
                  width: 18px;
                  height: 18px;
                  color: white;

                  @include transition($primary-transition);
                  font-size: 18px !important;
                }
              }

              input {
                &:disabled {
                  & ~ .t-input-control-indicator {
                    &::after {
                      opacity: 0 !important;
                      border-color: $input-border-color;
                    }
                  }
                }
              }
            }

            &-checkbox {
              display: block;
              position: relative;
              padding-left: 30px;
              cursor: pointer;
              color: $color-grey-darkest;
              font-weight: $weight-semibold;
              font-size: $size-text-body;
              line-height: $line-height-text-callout;

              @include typography-text-normal;
              @include transition($primary-transition);

              & input:checked ~ & {
                display: block !important;
              }

              & input {
                position: absolute;
                z-index: -1;
                opacity: 0;

                &:checked ~ & {
                  & .t-input-checkbox-indicator {
                    display: block;
                    background-color: $color-primary;
                    border: 2px solid $color-primary;

                    &::after {
                      display: block;
                    }
                  }
                }
              }

              &-indicator {
                position: absolute;
                top: 2px;
                left: 0;
                height: 18px;
                width: 18px;
                background-color: transparent;
                border: 2px solid $color-grey-dark;

                @include transition($primary-transition);

                &::after {
                  @include icon-default;
                  content: '\ea4d';
                  position: absolute;
                  display: none;
                  left: -2px;
                  top: -2px;
                  width: 18px;
                  height: 18px;
                  color: white;

                  @include transition($primary-transition);
                  font-size: 18px !important;
                }
              }

              &:hover {
                color: $color-primary !important;

                .#{$travlr-prefix} {
                  &input {
                    &-checkbox {
                      &-indicator {
                        border: 2px solid $color-primary !important;
                      }
                    }
                  }
                }
              }

              &:focus,
              &:visited {
                border-color: $input-border-focus;
              }

              &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
              }
            }

            //Tags using Checkboxes
            &-tag {
              display: none;

              //==> Checked
              &:checked {
                + .#{$travlr-prefix}input-tag {
                  &-primary {
                    background-color: $color-primary;
                    color: $color-white;
                  }

                  &-secondary {
                    background-color: $color-secondary;
                    color: $color-white;
                  }

                  &-tertiary {
                    background-color: $color-tertiary;
                    color: $color-white;
                  }
                }
              }

              //==> Disabled
              &:disabled {
                + .#{$travlr-prefix}input-tag {
                  &-label {
                    @include opacity(0.5);

                    &:hover {
                      cursor: not-allowed;
                      &.#{$travlr-prefix}input-tag {
                        &-primary {
                          background-color: unset;
                          color: $color-primary;
                        }

                        &-secondary {
                          background-color: unset;
                          color: $color-secondary;
                        }

                        &-tertiary {
                          background-color: unset;
                          color: $color-tertiary;
                        }
                      }
                    }
                  }
                }
              }

              &-label {
                cursor: pointer;
                padding: 5px 16px;
                font-size: 14px;
                font-weight: 600;
                line-height: 18px;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;

                @include border-radius(16px);
                @include transition(all 0.5s ease);
              }

              &-label-full {
                width: 100%;
                display: block;
                text-align: center;
              }

              //==> Styles
              &-primary {
                &.#{$travlr-prefix}input-tag {
                  &-label {
                    border: 1px solid $color-primary;
                    color: $color-primary;

                    &:hover {
                      background-color: $color-primary;
                      color: $color-white;
                    }
                  }
                }
              }

              &-secondary {
                &.#{$travlr-prefix}input-tag {
                  &-label {
                    border: 1px solid $color-secondary;
                    color: $color-secondary;

                    &:hover {
                      background-color: $color-secondary;
                      color: $color-white;
                    }
                  }
                }
              }

              &-tertiary {
                &.#{$travlr-prefix}input-tag {
                  &-label {
                    border: 1px solid $color-tertiary;
                    color: $color-tertiary;

                    &:hover {
                      background-color: $color-tertiary;
                      color: $color-white;
                    }
                  }
                }
              }
            }

            // Toggle
            &-toggle {
              display: none;

              // add default box-sizing for this scope
              &,
              &::after,
              &::before,
              & *,
              & *::after,
              & *::before,
              & + .t-input-toggle-btn {
                box-sizing: border-box;

                &::selection {
                  background: none;
                }
              }

              + .t-input-toggle-btn {
                outline: 0;
                display: inline-block;
                width: 40px;
                height: 22px;
                position: relative;
                cursor: pointer;
                user-select: none;
                vertical-align: middle;

                &::after,
                &::before {
                  position: relative;
                  display: block;
                  content: "";
                  width: 50%;
                  height: 100%;
                }

                &::after {
                  left: 0;
                }

                &::before {
                  display: none;
                }
              }

              &:checked + .t-input-toggle-btn::after {
                left: 50%;
              }

              &-label {
                margin-left: 8px;
                font-weight: $weight-semibold;
                font-size: $size-text-body;
                line-height: $line-height-text-callout;

                @include typography-text-normal;
              }
            }

            &-toggle-light {
              + .t-input-toggle-btn {
                background: #DDD;
                border-radius: 2em;
                padding: 2px;
                transition: all 0.4s ease;

                &::after {
                  border-radius: 50%;
                  background: #FFF;
                  transition: all 0.2s ease;
                }
              }

              &:checked + .t-input-toggle-btn {
                background: $color-primary;
              }
            }
          }
        }

        &input-group-with-icon {
          position: relative;

          .#{$travlr-prefix} {
            &input {
              &-icon {
                &-left {
                  left: 10px;
                  color: $color-grey-dark !important;
                  position: absolute;
                  top: 50%;

                  @include transform(translateY(-50%));
                  font-size: 20px !important;
                  z-index: 2;
                }

                &-right {
                  right: 10px;
                  color: $color-grey-med !important;
                  border: 0;
                  background-color: transparent;
                  padding: 0;
                  position: absolute;
                  top: 50%;

                  @include transform(translateY(-50%));
                  font-size: 20px !important;
                }

                &-group {
                  position: relative;
                }
              }

              &-text-icon-left,
              &-text-left,
              &-text-tinted-left {
                padding-left: 40px;
                padding-right: 36px !important;
              }

              &-text-icon-right,
              &-text-right,
              &-text-tinted-right {
                padding-right: 40px;
                padding-left: 36px !important;
              }
            }
          }
        }

        &input-number-dropdown {
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          width: 100%;
          background-color: white;
          z-index: 9;
          color: $color-grey-darkest;
          font-size: 14px;
          min-width: 300px;
          padding: 16px;
          border-radius: $radius;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;

          @include box-shadow($primary-box-shadow);
          @include transition($primary-transition);

          &.active {
            opacity: 1;
            visibility: visible;
            pointer-events: fill;
          }

          &-cadet {
            position: absolute;
            top: -12px;
            left: 10px;
            font-size: 16px;
            color: white;
          }
        }
      }
    }
  }

  .el-input-number {
    width: 128px;

    &__increase,
    &__decrease {
      background: $color-grey-lightest;
    }
  }

  .el-input__inner {
    &:focus {
      -webkit-user-select: none; /* Safari 3.1+ */
      -moz-user-select: none; /* Firefox 2+ */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Standard syntax */
    }
  }

  input[type="color"],
  input[type="date"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="password"],
  input[type="search"],
  input[type="tel"],
  input[type="text"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  select:focus,
  textarea {
    font-size: 16px;
    // -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none; // latest Opera versions support -webkit-
    // user-select: none;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }
}
