/********** For Prefix and Namespace *********/
$prefix:               t !default;
$namespace:            #{$prefix}-themes !default;

/* ************************************ */

/********** For Media Queries *********/
/** For Extra Small Screen / Phone **/
$screen-xs:            767px !default;
$screen-xs:            $screen-xs !default;
$screen-phone:         $screen-xs !default;

/** For Small Screen / Tablet **/
$screen-sm:            768px !default;
$screen-sm-min:        $screen-sm !default;
$screen-tablet:        $screen-sm-min !default;

/** For Medium Screen / Desktop **/
$screen-md:            992px !default;
$screen-md-min:        $screen-md !default;
$screen-medium:        $screen-md-min !default;

/** For Large Screen / Wide Desktop **/
$screen-lg:            1200px !default;
$screen-lg-min:        $screen-lg !default;
$screen-large:         $screen-lg-min !default;

/** For Screen Max **/
$screen-xs-max:                  ($screen-sm-min - 1) !default;
$screen-sm-max:                  ($screen-md-min - 1) !default;
$screen-md-max:                  ($screen-lg-min - 1) !default;

/* ************************************ */

/************** Theme Color **************/
$color-white: #FFF;
$color-default: #9B9B9B;
$color-black: #000;
$color-text: #333;
$color-grey: #999;
$color-grey-dark: #8E9497;
$color-dark: #384044;
$color-dark-grey: #38404C;
$color-paragraph: #898989;
$color-semi-dark-grey: #54585F;
$color-semi-dark: #616161;
$color-semi-grey: #54585A;
$color-semi-white-grey: #DDD;
$color-orange-filter: #E94F35;
$color-light-grey: #DDD;
$color-icon-grey:  #54585A;
$color-link-hover: #23527C;
$color-green: #2ECC71;
$color-front-star: #77A3B6;
$color-back-star: #CCC;
$color-red: #E54166;
$color-semi-red: #F45252;
$color-humm: #FF6900;
$color-grey-50: #F9FAFB;
$color-grey-100: #F3F4F6;
$color-grey-200: #E5E7EB;
$color-grey-300: #D1D5DB;
$color-grey-400: #9CA3AF;
$color-grey-500: #4B5563;
$color-grey-700: #374151;
$color-grey-800: #1F2937;
$color-grey-900: #111827;
$color-yellow-50: #FFFBEB;
$color-yellow-600: #D97706;
$color-yellow-400: #FBBF24;
$color-warning-v2: #F59E0B;
$color-green-50: #ECFDF5;
$color-blue-500: #3B82F6;

/* ************************************ */

/************** Print Color **************/
$print-color-border: #C0C0C0;
$print-color-text: #374044;

/* ************************************ */
