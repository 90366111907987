// Miscellaneous
@mixin all-zero() {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin scrollbar($value) {
  @include prefixer(scrollbar, $value, ("webkit", "moz", "o", "ms"));
}

@mixin box-shadow($value) {
  @include prefixer(box-shadow, $value, ("webkit", "moz", "o", "ms"));
}

@mixin no-box-shadow {
  @include prefixer(box-shadow, none, ("webkit", "moz", "o", "ms"));
}

@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}
