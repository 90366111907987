// Animation
@mixin transition($type) {
  @include prefixer(transition, $type, ("webkit", "moz", "o", "ms"));
}

@mixin transition-delay($time) {
  @include prefixer(transition-delay, $time, ("webkit", "moz", "o", "ms"));
}

@mixin animation($name,$time,$mode) {
  @include prefixer(animation, $name $time $mode, ("webkit", "moz", "o", "ms"));
}

@mixin animation-delay($time) {
  @include prefixer(animation-delay, $time, ("webkit", "moz", "o", "ms"));
}

@mixin keyframes( $content ) {
  @-webkit-keyframes #{$content} {
    @content;
  }

  @-moz-keyframes #{$content} {
    @content;
  }

  @-o-keyframes #{$content} {
    @content;
  }

  @keyframes #{$content} {
    @content;
  }
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
