/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Card **/
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

#cookieConsent {
  bottom: 0;
  left: 0;
  z-index: 9999999999;

  .ws-no-wrap {
    white-space: nowrap;
  }

  @include mobile {
    .t-display-table {
      display: block;

      .t-display-table-cell {
        display: block;

        &.t-m-b-8-mobile {
          margin-bottom: 8px;
        }
      }
    }
  }
}

/* ************************************ */
