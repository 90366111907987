/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 480px) {
  /******************************************
    Create an awesome styling here
  ******************************************/
  .#{$namespace} {
    & .c-home-mates-rates-banner {
      .img {
        &.desktop {
          display: none;
        }
      }
    }
  }
}

@media screen and (min-width: 481px) {
  .#{$namespace} {
    & .c-home-mates-rates-banner {
      .img {
        &.mobile {
          display: none;
        }
      }
    }
  }
}
