/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Banner Promotion **/
.#{$namespace} {
  & .c-banner-promotion {
    position: relative;
    width: 100%;
    background: rgba(55, 64, 68, 0.4);

    & .banner-promotion {
      &__wrapper {
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center center;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
          z-index: 0;
        }
      }

      &__container-content {
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &__content {
        width: 100%;
        height: 100%;

        & .content {
          &-left {
            height: 100%;
            text-align: right;
            padding: 58px 0;
            padding-right: 14px;

            @include tablet {
              padding: 32px 0;
            }

            & .content-left {
              &__wrapper {
                color: white;
                background-color: rgba(0, 0, 0, 0.15);
                display: inline-block;
                padding: 32px;
                width: 85%;

                @include tablet {
                  width: 100%;
                  height: 100%;
                  display: table;
                }

                & .title {
                  text-align: center;
                  font-size: 28px;

                  &.big-text {
                    font-size: 48px;
                    margin: 8px 0;
                  }
                }

                & .description {
                  text-align: center;
                  max-width: 55%;
                  margin: auto;
                  padding-top: 16px;
                  line-height: 28px;
                  font-size: 20px;

                  @include tablet {
                    max-width: 100%;
                  }
                }
              }
            }
          }

          &-right {
            height: 100%;
            padding-left: 14px;
            display: table;

            & .content-right {
              &__wrapper {
                width: 100%;
                display: table-cell;
                height: 100%;
                vertical-align: middle;

                &-inner {
                  color: white;
                  display: table;

                  & .title {
                    margin-bottom: 16px;
                  }

                  a {
                    margin-top: 10px;
                  }
                }
              }

              &__list-member-access {
                & .list-item {
                  display: table;
                  margin-bottom: 14px;

                  & svg {
                    display: table-cell;
                    width: 16px;
                    height: 16px;
                  }

                  & .p-wrapper {
                    display: table-cell;
                    vertical-align: middle;
                    padding-left: 8px;

                    & p {
                      margin-left: 12px;
                      display: table-cell;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/* ************************************ */
