/** This Place For Block Header **/
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{$namespace} {
  & .c-header {
    z-index: 1006;

    & #modalSearchSuggestion {
      display: none;

      &.active {
        display: table;
      }
    }

    .t-navbar-top-left-logo-image {
      width: auto;
      object-fit: contain;
      max-width: 200px;

      @include mobile {
        max-width: 150px;
      }
    }

    .t-navbar-top-right-search-suggestion-form {
      @include mobile {
        max-width: 100%;
      }
    }
  }

  .gsc-completion-container {
    box-shadow: none !important;
  }

  .t-w-auto {
    width: auto;
  }

  .t-h-auto {
    height: auto;
  }

  .get-extension-btn {
    border-radius: 100px !important;
  }
}
