@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-flex.scss";

.c-multi-currencies {
  .multi-currencies {
    &-symbol {
      width: 32px;
      font-weight: $weight-semibold;
    }

    &-name {
      color: $color-grey-500;
    }
  }

  .t-navbar-top-right-menus-list-item-child {
    border: none;
    padding: 0 !important;
    top: 100%;
    right: 0;

    & .t-currency-dropdown-wrapper {
      padding: 8px 0;
      margin-top: 2px;
      overflow: hidden;
      border: 1px solid $color-grey-light;
    }

    &-item {
      cursor: pointer;

      &-link {
        @include flex-init();
        @include align-items(center);
        @include justify-content(space-between);
      }

      &.activated {
        background-color: #F2F2F2;

        .multi-currencies-check-icon {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .t-navbar-top-right-menus-list-item-link {
    padding: 24px 4px;

    & .t-text-callout {
      line-height: $line-height-text-5;
    }

    & .t-p-l-2 {
      padding-left: 2px;
    }
  }
}
