@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  &.t-themes {
    & .trv-modal {
      background-color: rgba(0, 0, 0, 0.8);
      pointer-events: auto;
      transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
      z-index: -1;

      & .t-image-thumb-small {
        width: 100%;
      }

      & .t-modal-box {
        padding: 25px 14px;

        @include media-up(sm) {
          padding: 10px 0 0;
        }
      }

      &__content {
        height: 100%;
        overflow: visible;
        transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
        transition-delay: 0.4s;

        @include media-up(sm) {
          height: auto;
          overflow: auto;
          top: 53%;
        }
      }

      &__overlay {
        opacity: 1;
        transition: none;
        visibility: visible;
      }

      &__header {
        padding: 24px;
        min-height: 52px;
        text-align: left;
        box-shadow: none;

        @include media-up(sm) {
          padding: 0  0 24px;
          text-align: left;
          margin-top: 16px;
        }
      }

      &__body {
        height: 93%;
        overflow: auto;
        padding: 0 24px;

        @include media-up(sm) {
          height: auto;
          overflow: visible;
          padding: 0;
        }

        & .max-body-text {
          max-width: 420px;
        }
      }

      &__close {
        position: absolute;
        right: 14px;
        top: 14px;
        z-index: 10;

        @include media-up(sm) {
          right: 24px;
          top: 24px;
        }
      }

      &__noTitle {
        margin-top: 32px;
      }

      &-opened {
        opacity: 1;
        visibility: visible;
        z-index: 2200;

        & .trv-modal {
          &__content {
            opacity: 1;

            @include media-up(sm) {
              top: 50%;
            }
          }
        }
      }

      &.full-height-modal {
        & .trv-modal {
          &__content {
            @include media-up(sm) {
              height: 100%;
            }
          }

          &__body {
            @include media-up(sm) {
              overflow: auto;
              height: 89%;
            }
          }
        }
      }

      // Override Map Modal: TRAV-4811
      &#trv-map-modal {
        @include media-up(sm) {
          .trv-modal__content {
            overflow: visible !important;
          }
        }
      }
    }

    // Override share modal: TRAV-6258
    .trv-share-modal {
      .trv-modal__content {
        @include mobile {
          height: auto;
          bottom: 0;
          top: auto;
          transform: translate3d(-50%, 0, 0);
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
      }
    }
  }
}
