/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Home Shortcut Panel **/
.#{$namespace} {
  & .c-home-shortcut-panel {
    margin-top: 32px;
    z-index: 1005;

    & .home-shortcut-panel {
      &__container {
        @include transition($primary-transition);

        &.active {
          width: 250px;
        }

        & .link-col-wrapper {
          position: relative;

          @include transition($primary-transition);

          &:not(:last-child)::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 88px;
            background: rgba(0, 0, 0, 0.2);

            @include transform(translateY(-50%));
          }

          & .link-wrapper {
            display: inline-block;
            padding: 24px 0;

            & .link-icon-wrapper {
              border-radius: 50%;
              width: 48px;
              height: 48px;
              background: #006272;
              margin: 0 auto;
              display: table;

              @include transition($primary-transition);

              & .icon-wrapper {
                width: 100%;
                height: 100%;
                display: table-cell;
                vertical-align: middle;

                & .t-icon {
                  color: white;

                  @include transition($primary-transition);
                }
              }
            }

            & .link-item {
              &:hover {
                & .link-icon-wrapper {
                  background: transparent;
                }

                & .t-icon {
                  color: $color-tertiary;
                }
              }

              &.active {
                & .link-icon-wrapper {
                  background: transparent;
                }

                & .t-icon {
                  color: $color-tertiary;
                }
              }
            }

            & .title {
              text-transform: capitalize;
              padding: 0 8px;
            }
          }
        }

        & .c-card {
          border-radius: 4px;

          @include box-shadow(0 2px 8px 0 rgba(0, 0, 0, 0.2));
        }
      }

      &__wrapper {
        overflow: hidden;
      }

      &__content {
        position: relative;

        &.active {
          right: 0;
          opacity: 1;
        }
      }
    }
  }
}

/* ************************************ */
