@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.cart {
  &__counter {
    background-color: $color-danger;
    border-radius: 16px;
    color: $color-white;
    display: block;
    font-size: 10px;
    font-weight: 600;
    left: 50%;
    line-height: 0;
    padding: 9px 8px;
    position: absolute;
    text-align: center;
    top: -6px;
  }

  &__outer-list {
    right: 0;
    position: absolute;
    top: 100%;

    @include media-up(md) {
      left: 50%;
      right: auto;
      transform: translateX(-50%);
    }
  }

  &__list {
    background-color: $color-white;
    box-shadow: 0 0 4px rgba($color-grey-darkest, 0.44);
    padding: 16px;
    position: relative;
    width: 300px;
  }

  &__item {
    border-bottom: 1px solid $color-grey-lighter;
    margin-bottom: 8px;
    padding-bottom: 8px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  & .cart-item {
    &__title {
      height: 30px;
      overflow: hidden;
    }
  }
}
