@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{travlr-ui} {
  & .media-preview {
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &--visible {
      opacity: 1;

      & .media-preview {
        &__content {
          transform: scale(1);
        }
      }
    }

    &__wrapper {
      background-color: rgba($color-grey-darkest, 0.8);
      height: 100%;
      position: absolute;
      width: 100%;
    }

    &__entry {
      left: 50%;
      max-height: 521px;
      max-width: 855px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    &__iframe {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;

      iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
      }
    }

    &__content {
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      transform: scale(0.7);
      transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &__close {
      color: $color-white;
      position: absolute;
      right: 10px;
      top: -36px;
    }

    & .t-card-skeleton {
      padding-left: 16px;
      padding-right: 16px;

      & .t-card-skeleton-text {
        display: none;
      }

      & .t-card-skeleton-image {
        height: 100%;
      }
    }
  }
}
