@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-flex.scss";

.c-aside {
  .t-navbar-sidebar-header-logo {
    object-fit: contain;
  }

  .currency-wrapper {
    &.active {
      background-color: #F2F2F2;
    }

    .t-navbar-sidebar-body-child-item-link {
      @include flex-init();
      @include align-items(center);
      @include justify-content(space-between);
    }
  }
}
