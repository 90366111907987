/******************************************
  Some tips for creating responsive blocks / modules :
  - Just copy paste this structure of styling code into your new responsive layouts or modules styling file
  - Dont forget to register your responsive into `state/_responsive.scss`
******************************************/

@media screen and (max-width: 768px) {
  .#{$namespace} {
    & .c-banner-promotion {
      & .banner-promotion {
        &__content {
          & .content {
            &-left {
              & .content-left {
                padding-right: 0;

                &__wrapper {
                  width: 88%;

                  & .title {
                    font-size: 20px;

                    &.big-text {
                      font-size: 32px;
                    }
                  }

                  & .description {
                    max-width: 65%;
                    font-size: 14px;
                  }
                }
              }
            }

            &-right {
              & .content-right {
                padding-left: 0;

                &__wrapper {
                  &-inner {
                    & .title {
                      font-size: 14px;
                    }
                  }
                }

                &__list-member-access {
                  & .list-item {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/******************************************
  Max Phone Screen
******************************************/
@media screen and (max-width: $screen-xs-max) {
  .#{$namespace} {
    & .c-banner-promotion {
      & .banner-promotion {
        &__container-content {
          padding-bottom: 24px !important;
        }

        &__wrapper {
          height: auto;
        }

        &__content {
          & .content {
            &-left {
              padding-top: 24px;
              padding-bottom: 8px;
              padding-right: 0;

              & .content-left {
                &__wrapper {
                  width: 100%;

                  & .title {
                    text-align: center;
                    font-size: 20px;

                    &.big-text {
                      font-size: 32px;
                    }
                  }

                  & .description {
                    max-width: 70%;
                    font-size: 14px;
                  }
                }
              }
            }

            &-right {
              padding-left: 0;

              & .content-right {
                &__wrapper {
                  &-inner {
                    display: block;

                    & .title {
                      font-size: 14px;
                    }

                    & .#{$prefix} {
                      &-btn {
                        margin-top: 16px;
                      }
                    }
                  }
                }

                &__member-benefit {
                  padding-right: 8px;
                  padding-left: 8px;
                }

                &__list-member-access {
                  & .list-item {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
