@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-text.scss";

.c-accordion-item {
  position: relative;

  & .t-inner-html {
    & > {
      // Reset Body Typography
      & h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: $weight-bold;

        @include typography-text-normal;
        margin: 12px 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      & ul {
        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
    // temp style
    & a {
      color: $color-primary;

      &:hover {
        color: $color-secondary;
      }
    }
  }

  .t-accordion-item-header-with-icon {
    display: table;
    width: 100%;
    height: 57px;
    min-height: 45px;
    cursor: pointer;
  }

  .t-accordion-icon.t-icon.icon-angle-down-small {
    width: 24px;
  }

  .t-accordion-item-icon-menu {
    height: 35px;
  }

  .t-accordion-item-list-a-has-child {
    width: calc(100% - 20px);
  }

  .t-accordion-item-list-a-no-child {
    width: 100%;
  }

  .t-navbar-sidebar-body-child-item-link {
    width: 90%;
  }

  .t-navbar-sidebar-body-child-item-arrow-btn {
    width: 24px;
  }
}
