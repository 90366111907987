@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.c-counter {
  .col {
    width: 50%;
    border: 1px black solid;
  }

  .label-container {
    width: 55%;
  }

  .counter-container {
    width: 45%;
    text-align: justify;

    .counter-inner-container {
      .btn-container {
        width: 30%;

        .t-icon {
          font-size: 12px;
        }
      }

      .val-container {
        width: 40%;
      }
    }
  }
}
