/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Dream Plan Book **/
.#{$namespace} {
  & .c-home-dream-plan-book {
    min-height: 120px;

    &:last-child {
      padding-bottom: 64px;
    }

    & .dream-plan-book {
      &__container {
        position: relative;
      }

      &__wrapper-content {
        position: relative;
        height: 120px;

        & .content {
          &-item {
            position: relative;
            display: inline-block;
            padding: 24px;
            padding-left: 0;
            height: 100%;
            border-right: 1px solid $color-semi-white-grey;

            &:last-child {
              border-right: none;
            }

            @include clearfix;

            &__image {
              float: left;
              padding-top: 8px;
              padding-right: 8px;
              padding-left: 24px;
            }

            &__text {
              padding-left: 72px;
            }
          }
        }
      }
    }
  }
}

/* ************************************ */
