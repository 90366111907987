// Floating
@mixin clearfix {
  &::after {
    clear: both;
    content: " ";
    display: table;
  }
}

@mixin loopColumn($point) {
  @for $i from 1 through $max-col {
    .#{$travlr-prefix}col-#{$point}-#{$i} {
      width: (100% / ($max-col/$i));
    }
  }
}
