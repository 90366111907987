/** This Place For Block Footer **/
@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{$namespace} {
  .c-footer {
    background: $color-tertiary;

    .footer {
      &-list {
        position: relative;

        &::after {
          clear: both;
        }

        & > li {
          width: 50%;
          float: left;

          & > a {
            color: white;
          }
        }

        &:hover {
          & > li {
            & > a {
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        &.social-media {
          & > li {
            width: auto;
          }
        }

        & .social-media {
          &-image {
            height: 28px;

            &-item {
              margin: auto;
              height: 20px;
            }
          }
        }
      }
    }

    .t-navbar-bottom-widget-item {
      border-color: rgba(255, 255, 255, 0.25);
    }

    .t-navbar-separator {
      opacity: 0.25;
    }

    .t-navbar-bottom-main-left-copyright {
      max-width: 600px;
    }

    .consent-text {
      font-size: 12px;
      margin-bottom: 10px;
      text-align: justify;
    }

    .payment-logo-item-image {
      height: 24px;
    }

    .t-navbar-bottom-main-left-logo-bb {
      max-width: 200px;
      object-fit: contain;
    }
  }
}

/* ************************************ */
