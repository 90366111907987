@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.c-location {
  & .t-input-group-with-icon {
    & .t-input-icon-group {
      & .t-input-global-search {
        padding: 0 38px;
        min-width: 100%;

        @include media-up(sm) {
          min-width: 250px;
        }

        @include media-up(lg) {
          min-width: 355px;
        }
      }

      & .t-input-icon-right {
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        gap: 8px;

        & .icon__loading,
        & .icon__clear {
          padding: 0;
          margin: 0;

          &:hover,
          &:focus {
            opacity: 50%;
          }
        }
      }
    }

    & .t-input-dropdown {
      // display: none;
      background-color: $color-white;

      & .t-input-dropdown__item {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 12px 8px;

        &:first-child {
          border-radius: $radius $radius 0 0;
        }

        &:last-child {
          border-radius: 0 0 $radius $radius;
        }

        &:hover,
        &:focus {
          background-color: $color-grey-lightest;
        }
      }

      & .t-input-dropdown__empty {
        display: block;
        padding: 12px;
        color: $color-grey-darkest;
        font-weight: $weight-bold;
      }
    }

    & .t-icon {
      color: $color-grey-med;
    }
  }

  @include mobile {
    & .t-btn {
      width: 100%;
    }
  }
}
