@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.#{$namespace} {
  // override trv-header component
  @include mobile {
    &.gsc-mobile-active {
      .gssb {
        &_e {
          box-shadow: none !important;
          border-radius: 0 0 $radius $radius;
        }

        &_c {
          left: 0 !important;
          width: 100% !important;
          top: 100px !important;
          z-index: 1008 !important;
        }
      }

      .gsc-completion-container {
        width: 100%;
        border: none;
      }

      td.gssb_a {
        border: none;
      }
    }
  }

  .trv-header {
    .google-search-wrapper {
      #___gcse_0 {
        width: 100%;
      }

      @include mobile {
        &.active {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          padding-right: 0;
          z-index: 99;
          overflow: auto;
          background-color: #FFF;
          display: block !important;
        }
      }

      .gsc {
        &-search-box {
          width: 100%;
          margin-top: 0;
          display: flex;
          margin-bottom: 0;

          @include mobile {
            display: table;
          }
        }

        &-input {
          height: 48px;
          min-width: 280px;
          padding-right: 0;

          &-box {
            border-radius: $radius !important;
            height: 48px;
            overflow: hidden;

            @include mobile {
              height: 48px;
              padding: 0;
            }
          }
        }

        &-webResult {
          display: none !important;
        }

        &-results-wrapper-visible {
          display: none;
        }

        &-search-button {
          display: none;
        }

        &-control-cse {
          padding: 0;

          .gsc-input {
            height: 40px !important;

            @include mobile {
              height: 48px;
            }
          }
        }

        &-control-wrapper-cse {
          height: 100%;
          display: flex;
        }
      }

      .gsib {
        &_a {
          background-color: $color-grey-lightest;
          border-radius: $radius;
          width: 100%;
          display: flex;
          align-items: center;
          height: 48px;
          position: relative;

          @include mobile {
            height: 46px;
            padding: 0;

            &::after {
              content: "\EAD7";
              font-family: TRAVLR !important;
              position: absolute;
              font-size: 20px;
              color: $color-grey-dark;
              top: 50%;
              right: 0;
              transform: translateY(-50%) translateX(-50%);
            }
          }

          .gsc-input {
            background-color: $color-grey-lightest;
            font-size: 16px;
            height: 40px !important;
            border-radius: $radius;

            @include mobile {
              height: 46px !important;
              margin: 0 !important;
              padding: 16px !important;
              padding-right: 32px !important;
            }
          }
        }

        &_b {
          display: none;
        }
      }
    }

    .google-search-mobile {
      & .google-close-button {
        padding: 14px 0;
        height: fit-content;
      }

      @include mobile {
        padding: 16px;
        box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .gsc-completion-container {
    width: 339px !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 0 $radius $radius !important;
    overflow: hidden;

    @include tablet {
      width: 340px !important;
    }
  }

  .gssb {
    &_a {
      padding: 16px;

      td {
        cursor: pointer;
      }
    }

    &_c {
      z-index: 1;

      @media (min-width: 600px) {
        min-width: 400px;
      }
    }

    &_e {
      box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.2);
      border-radius: $radius;
    }
  }

  .gs-no-results-result .gs-snippet,
  .gs-error-result .gs-snippet {
    background-color: transparent;
    border: none;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .gs-web-image-box {
      padding: 2px 2px 0 0 !important;
    }

    .gsc {
      &-search-box {
        padding: 2px !important;
      }

      &-url-top {
        padding-left: 0 !important;
      }

      &-cursor-box {
        text-align: center !important;
      }

      &-thumbnail-inside {
        padding-left: 0 !important;
      }

      .gsc-cursor-page {
        width: 40px !important;
        height: 40px;
        display: inline-block !important;
        text-align: center;
        line-height: 40px;

        &:hover {
          color: #FFF;
          background-color: #2392EC;
          border-radius: 100%;
          transition: all 0.5s ease;
          text-decoration: none !important;
        }
      }
    }
  }

  .gstl_50 {
    &.gssb_c {
      position: fixed !important;
      z-index: 1009;
      margin-left: -20px !important;
      top: 148px !important;
      width: 340px !important;
      min-width: auto;
      border: 1px solid $color-grey-lighter;
      border-top: none;
      border-radius: 0 0 $radius $radius;

      @include tablet {
        margin-left: -20.5px !important;
      }
    }
  }

  // google search result
  .c-google-search {
    max-width: 768px;
    margin: 0 auto;

    .gs {
      &-spelling {
        padding: 0;

        a {
          color: #333;
        }
      }

      &-web-image-box,
      &-promotion-image-box {
        width: 80px;
        height: 80px;
        padding: 0;
      }
    }

    .gsc {
      &-wrapper {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      &-search-box {
        margin-top: 16px;
        width: 100%;
        margin-bottom: 0;
      }

      &-orderby {
        display: flex;
        align-items: center;

        &-container {
          text-align: left;
        }
      }

      &-search-button {
        height: 40px;
        background-color: $color-grey-dark;
        border: none;
        border-radius: $radius;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &-resultsbox-visible {
        max-width: 768px;
      }

      &-result {
        line-height: 1.4 !important;
        background: #FFF;

        &-info-container {
          display: none;
        }
      }

      &-table-cell-snippet-close {
        vertical-align: top;
        width: 60%;
        font-size: 14px;
        padding-left: 16px;
        line-height: 1.3em;

        @include mobile {
          width: 100%;
        }
      }

      &-control-cse {
        background-color: transparent;
        border: none;

        @include mobile {
          padding: 0;
        }

        & .gs-title * {
          font-size: 20px !important;
          color: $color-grey-darkest !important;
        }

        & .gs-image {
          max-width: 80px;
          min-width: 80px;
          height: 80px;
          object-fit: cover;
          transition: all 0.5s ease;
          border: none !important;
        }

        .gsc-cursor-box {
          text-align: center;
          margin-top: 32px;
          margin-bottom: 16px;
        }

        .gcsc-more-maybe-branding-root {
          display: none;
        }

        .gsc-input {
          padding-right: 0 !important;
          height: 40px !important;
          background-image: none !important;
          display: flex;
          align-items: center;

          &::placeholder {
            font-size: 16px;
          }
        }

        .gsc-adBlock {
          display: none !important;
        }

        .gs-visibleUrl {
          color: $color-grey-dark;
          line-height: 20px !important;
        }

        @media only screen and (min-width: 768px) {
          #gs_tti51 {
            margin-top: 5px !important;
            box-shadow: none !important;
          }

          .gsc-cursor-current-page {
            background-color: $color-grey-dark !important;
            color: #FFF !important;
            border-radius: 100%;
          }

          .gsc-cursor-page {
            width: 40px !important;
            height: 40px;
            display: inline-block !important;
            text-align: center;
            line-height: 40px;

            &:hover {
              color: #FFF;
              background-color: $color-grey-dark;
              border-radius: 100%;
              transition: all 0.5s ease;
              text-decoration: none !important;
            }
          }
        }
      }

      &-table-cell-thumbnail {
        width: 80px;
        height: 80px;
      }

      &-url-top {
        padding: 0;
      }

      &-thumbnail-inside {
        margin-bottom: 0;
        padding: 0;
      }

      &-input-box {
        border-radius: $radius;
        height: 40px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0;
        overflow: hidden;
      }

      &-results {
        &.gsc-webResult {
          border-radius: $radius;
          padding: 32px;
          background: #FFF;
          width: 100%;

          @include mobile {
            padding: 16px;
          }
        }
      }

      &-webResult {
        &.gsc-result {
          margin: 0;
          padding-bottom: 24px;
          margin-bottom: 16px;
          border-bottom: 1px solid #E5E7EB;
          padding-top: 0;

          &:last-child {
            border: 0;
            padding: 0;
            margin: 0;
          }
        }
      }

      &-above-wrapper-area {
        border-bottom: none;
        max-width: 768px;
        padding-top: 16px;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &-table-result {
        margin: 12px 0 0;
        display: flex;
        padding: 0;
      }

      &-find-more-on-google {
        margin-top: 10px !important;
      }
    }
  }
}
