// TODO: update UIKit button, use padding instead of line-height
.travlr-ui {
  .section-title-button__header h2 {
    line-height: 1.2;
    display: inline;
  }

  .section-title-button__header i {
    vertical-align: text-bottom;
  }

  .section-title-button__wrapper-action > .t-btn-default {
    height: auto;
    line-height: 1.2;
    padding: 5px 10px !important;
  }

  @media screen and (max-width: 1024px) {
    .section-title-button__wrapper-action {
      display: none !important;
    }
  }
}
