@import "../../../../../assets/scss/variables.scss";

.travlr-ui {
  & .trv-mobile-wrapper {
    background-color: $color-white;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    pointer-events: none;
    top: 0;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    visibility: hidden;
    width: 100%;
    z-index: -1;

    @media (min-width: $screen-sm) {
      display: none;
    }

    &-opened {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      z-index: 9999;
    }

    &__content {
      display: block;
      position: relative;
    }

    &__title {
      &.t-heading-4 {
        font-size: 16px;
      }
    }

    &__header {
      box-shadow: 0 1px 2px 0 rgba($color-black, 0.2);
      font-size: 16px;
      padding: 14px 45px;
      position: relative;
      text-align: center;
      z-index: 10;

      @media (min-width: $screen-xs) {
        padding-left: 14px;
        padding-right: 14px;
        text-align: left;
      }
    }

    &__close {
      position: absolute;
      right: 14px;
      top: 14px;
      z-index: 12;
    }
  }
}
