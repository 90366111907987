@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-transform.scss";

.travlr-ui {
  .t-pointer-events-none {
    pointer-events: none;
  }

  & .accommodation-search {
    & .t-icon {
      &--form {
        color: $color-grey-dark;
        left: 8px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    & .t-btn-accommodation {
      text-align: center;

      .input-label {
        display: none;

        @include media-up(lg) {
          display: block;
        }
      }

      & .t-btn {
        white-space: nowrap;
        width: 100%;

        @include media-up(md) {
          width: auto;
        }

        @include media-up(lg) {
          width: 100%;
        }
      }
    }

    & .input-destination-group {
      &.input-error {
        & .t-error-text {
          font-size: 12px;
          margin-top: 2px;
        }

        & .multiselect__tags {
          border: 2px solid $color-danger;
        }
      }
    }

    & .guest-dropdown-counters-container {
      width: 100%;

      & .t-input-icon-right {
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }

      & .guest-count-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      & .guest-count-btn {
        padding: 10px 16px;
        font-size: 16px;
        text-align: left;
        height: 40px;
      }

      & .guest-count-box {
        border-bottom: 1px solid $color-grey-light;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &:nth-last-child(2) {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      & .select-ages {
        & .t-icon {
          right: 10px;
          top: 8px;
        }

        & .child-ages {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          background-color: $color-grey-lightest;
          border-radius: 4px;
          border: solid 1px $color-grey-light;
          box-shadow: none;
          height: 40px;
          padding-left: 12px;
          padding-right: 12px;
          width: 100%;
        }
      }

      & .dropdown {
        background-color: $color-white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        padding: 16px;
        margin-top: 8px;

        @include media-up(md) {
          width: 245px;
        }

        @include media-up(lg) {
          width: 100%;
        }

        &--opened {
          opacity: 1;
          visibility: visible;
        }

        &--rounded {
          border-radius: 4px;
        }

        &__entry {
          max-height: 400px;
          overflow-y: auto;
        }
      }
    }

    & .input-label {
      font-size: 14px;
      font-weight: 600;
      left: 0;
    }

    & .multiselect {
      white-space: nowrap;

      &__tags {
        padding-left: 16px;
      }

      &__single {
        display: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__placeholder {
        font-size: 16px;
        color: $color-grey-dark;
      }

      .caret {
        background: $color-white;
        border-radius: 4px;
        color: $color-grey-darkest;
        display: block;
        left: 0;
        line-height: 40px;
        height: 100%;
        overflow: hidden;
        padding-right: 16px;
        padding-left: 16px;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }

      &.multiselect--active {
        .multiselect__tags {
          padding-left: 8px;
        }
      }

      .multiselect__select {
        &::before {
          content: "";
          display: none;
        }
      }

      .multiselect__option--highlight {
        background: $color-grey-lightest;
        color: $color-grey-darkest;
      }
    }

    .option {
      &__image {
        min-height: 40px;
        min-width: 40px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    & .t-form--calendar {
      &.t-row {
        @include media-up(md) {
          margin-left: 0;
          margin-right: 0;
        }

        > .t-col-xs-6 {
          @include media-up(md) {
            padding-left: 0;
            padding-right: 0;
          }

          &:first-child {
            padding-right: 0;

            & .el-date-editor {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }

          &:last-child {
            padding-left: 0;

            & .el-date-editor {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }

          & .t-input-group-with-icon {
            margin-bottom: 0;
            width: 100%;

            & .t-input-global-datepicker {
              max-width: none !important;
              padding: 0 !important;
              width: 100%;

              input {
                padding-left: 12px;
                padding-right: 12px;
              }

              .el-input__prefix {
                display: none;
              }
            }
          }
        }
      }
    }

    .accommodationCheckoutDate .t-input-text {
      border-left: 0 !important;
    }

    // New Search
    .c-accommodation-ajax-auto-complete {
      & .ajax-auto-complete {
        &-popup {
          top: 100%;
          left: 0;
          opacity: 0;
          width: 100%;
          max-height: 250px;
          overflow: auto;
          visibility: hidden;
          pointer-events: none;
          z-index: 9;

          @include transition($primary-transition);

          @include mobile {
            max-width: 100%;
          }

          &.active {
            opacity: 1;
            visibility: visible;
            pointer-events: fill;
          }
        }

        &-input {
          padding-right: 36px !important;

          &-loader {
            top: 10px;
            right: 8px;
          }
        }

        &-result {
          cursor: pointer;

          @include transition($primary-transition);

          &:hover {
            background-color: $color-grey-lightest;
          }
        }

        &-suggestion-icon {
          @include transform(rotate(45deg));
        }
      }
    }
  }
}
