@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";
@import "~/assets/scss/variables.scss";

.#{travlr-ui} {
  .card-deal {
    .t-m-w-100 {
      max-width: 100%;
    }

    .t-flex-col {
      flex-direction: column;
    }

    &__label {
      color: $color-grey-darkest;
      border: 1px solid $color-grey-300;
      font-weight: 500;

      &-empty {
        visibility: hidden;

        span {
          height: 20px;
        }
      }
    }

    &__header {
      height: 100%;
    }

    &__link {
      flex-grow: 1;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &__title {
      color: $color-grey-900;
      line-height: 28px;
    }

    &__place {
      color: $color-grey-700;
      line-height: 24px;
    }

    &__lower {
      align-content: flex-end;
    }

    &__tag {
      color: $color-grey-700;
      margin-bottom: 2px;
    }

    &__price {
      color: $color-grey-900;
      font-size: 18px;
    }

    &__addlabel {
      background-color: $color-green-50;
      color: $color-grey-900;
    }

    &__special-label {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 20px;
      font-weight: 600;
      left: 0;
      line-height: 1.2;
      top: 24px;
    }

    &--fullwidth {
      & .card-deal {
        &__header {
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            .c-image {
              padding-top: 100%;
            }
          }
        }

        &__price {
          @media only screen and (min-width: 768px) and (max-width: 1024px) {
            overflow: visible !important;
          }
        }
      }

      @include media-up(md) {
        & .t-card-content-header-background {
          border-top-right-radius: 0;
        }

        .c-enquire-btn {
          .t-icon {
            display: inline-block;
          }
        }

        & .card-deal {
          &::after {
            content: "";
            clear: both;
            display: block;
          }

          &__upper {
            min-height: 142px;
          }

          &__lower {
            min-height: 86px;
          }

          &__price {
            font-size: 20px;
            line-height: 28px;
          }

          &__title {
            font-size: 24px;
            line-height: 32px;
            min-height: 32px;
          }

          &__header {
            float: left;
            max-height: none !important;
            width: 50%;

            img {
              height: 100%;
            }
          }

          &__body {
            float: left;
            width: 50%;
            height: 100%;
          }
        }

        &.card-deal {
          & > .t-position-relative {
            & .t-card-skeleton-image {
              border-top-right-radius: 0;
            }

            & > .t-card-skeleton {
              & .t-card-skeleton-image {
                float: left;
                height: 100%;
                width: 50%;
              }

              & .t-card-skeleton-text {
                float: left;
                padding: 40px;
                width: 50%;
              }
            }
          }
        }
      }
    }

    .c-image {
      padding-top: 66.66%; /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
    }

    .t-card-content-header,
    .t-card-title-small {
      min-height: auto !important;
      max-height: none !important;

      &.t-h-100 {
        height: 100% !important;
      }
    }

    &__action {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        flex-direction: column;
      }
    }

    .disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
