.c-cta-banner-layer-widget {
  .content-wrapper {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .t-btn {
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .c-image {
      height: auto !important;
      width: auto !important;
    }
  }

  .cta-icon {
    max-width: 24px;
    max-height: 24px;
    object-fit: contain;
  }
}
