.c-card-accommodation {
  display: inline-block;

  .location {
    min-height: 22px;
  }

  .t-card-content-footer {
    position: relative;
    height: 76px;
    min-height: 148px;

    &-left {
      float: left;
      height: 100%;
    }

    &-right {
      float: right;
      height: 100%;
    }

    &-wrapper {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .not-available {
      min-height: 48px;
    }
  }
}
