@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-prefixer.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-animations.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-responsive.scss";

.travlr-ui {
  .c-federated-search-top-filter {
    position: relative;

    & .t-form {
      padding-top: 32px;

      & .t-input-group-with-icon {
        & .t-input-icon-group {
          & .t-input-global-search {
            padding-left: 38px;
            padding-right: 15px;
            min-width: 100%;

            @include media-up(sm) {
              min-width: 250px;
            }

            @include media-up(lg) {
              min-width: 355px;
            }
          }
        }
      }
    }

    & .filter-search-types {
      display: block;

      @include media-up(sm) {
        display: inline-block;
        width: 170px;
      }

      @include media-up(lg) {
        width: auto;
      }
    }

    & .federated-search {
      &-field {
        display: block;
        margin-bottom: 12px;
        z-index: 2;

        @include media-up(sm) {
          display: inline-block;
          margin-bottom: 0;
          margin-right: 8px;
        }
      }

      &__button {
        display: block;
        margin-top: 12px;

        .t-btn {
          width: 100%;

          @include media-up(sm) {
            width: auto;
          }
        }

        @include media-up(sm) {
          display: inline-block;
          margin-top: 0;
          margin-left: 8px;
        }
      }

      &-chechbox-filter {
        display: none;
        left: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 12;

        @include transition($primary-transition);

        @media (min-width: $screen-sm) {
          display: block;
        }

        &.active {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    & .t-modal-content {
      @include tablet {
        padding: 0 !important;
      }
    }

    & .trv-mobile-wrapper {
      & .federated-search-chechbox-filter {
        display: block;
      }
    }

    #federatedSearchCheckboxes {
      @include placeholder {
        color: $color-grey-darkest;
      }
    }
  }
}
