@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "@/Library/UiKit/src/scss/uikit/mixins/_mixins-placeholders.scss";

@keyframes line-animate {
  0% {
    width: 0;
  }

  50% {
    width: 100%;
  }

  0% {
    width: 0;
  }
}

.el-range-input {
  @include placeholder {
    color: $color-grey-dark !important;
    font-size: 16px;
  }
}

/* custom date picker popper */
.trv-date-picker {
  &.el-date-picker {
    border-radius: $radius;
    border: $primary-border !important;
    box-shadow: 0 32px 72px 0 #32325D1F !important;

    @include mobile {
      width: calc(100% - 48px);
      max-width: 100%;
    }

    .el-picker-panel {
      &__body {
        min-width: 100%;
      }
    }
  }

  /* popper start */
  &.el-popper {
    &[x-placement^="bottom"] {
      margin-top: 10px;
    }

    &[x-placement^="top"] {
      margin-bottom: 10px;
    }

    .popper__arrow {
      display: none;
    }
  }

  /* popper end */

  .el-date-picker {
    /* calendar head start */
    &__header {
      border-bottom: $primary-border;
      padding: 16px 24px;
      height: auto;
      margin: 0;

      @include mobile {
        padding: 8px 16px;
        height: 48px;

        div {
          height: 32px;
        }
      }

      div {
        font-weight: bold;
        color: $color-grey-900;
      }

      .el-picker-panel__icon-btn {
        @include mobile {
          margin-top: 0;
          height: 32px;
        }

        &.el-icon-d-arrow-left,
        &.el-icon-d-arrow-right {
          display: none;
        }
      }

      &-label {
        font-weight: bold;
        color: $color-grey-900;
      }
    }

    /* calendar head end */
  }

  .el-picker-panel {
    /* calendar body start */
    &__content {
      padding: 0;

      .disabled-arrow {
        display: none !important;
      }

      /* data table  */
      .el-date-table {
        padding: 0;

        th {
          border-bottom: 0 !important;
        }

        span {
          font-weight: normal;
        }

        td {
          width: 38px;
          height: 38px;
          padding: 0;
          border-radius: 100%;
          border: 0 !important;
          background-color: transparent !important;
          transition: none !important;

          &.available {
            background-color: transparent !important;

            span {
              color: $color-grey-900 !important;
            }

            &.current,
            &:hover {
              span {
                border: 1px solid $color-grey-900 !important;
                background-color: transparent !important;
              }
            }
          }

          &.normal {
            &.disabled {
              span {
                background-color: transparent !important;
                text-decoration: line-through;
              }
            }
          }

          &.today {
            span {
              color: $color-blue-500 !important;
              font-weight: bold;
            }
          }

          &.disabled {
            div,
            span {
              background-color: transparent !important;
              text-decoration: line-through;
            }
          }

          &.prev-month,
          &.disabled {
            div {
              color: $color-grey-300;
            }
          }

          div,
          span {
            height: 38px;
            width: 38px;
            padding: 0;
            line-height: 38px;
            margin: 0;
          }

          span {
            border: 1px solid transparent;
          }
        }
      }

      @include mobile {
        width: 100%;

        .el-date-table {
          padding: 16px;

          th {
            padding: 0;
          }

          td {
            width: 32px;
            height: 32px;

            div,
            span {
              height: 32px;
              width: 32px;
              line-height: 32px;
            }
          }
        }
      }
    }

    /* calendar body end */
  }
}

/* custom date range picker popper */
.trv-date-range-picker {
  &.is-loading {
    &::after {
      content: '';
      position: absolute;
      top: 62px;
      left: 0;
      background: $color-grey-900;
      width: 100%;
      height: 1px;
      z-index: 5;
      cursor: not-allowed;
      display: flex;
      align-items: center;
      justify-content: center;
      animation: line-animate 3s linear infinite;

      @include mobile {
        top: 48px !important;
      }
    }
  }

  &.el-date-range-picker {
    width: 100%;
    max-width: 700px;
    border-radius: $radius;
    border: $primary-border !important;
    box-shadow: 0 32px 72px 0 #32325D1F !important;

    @include mobile {
      width: calc(100% - 48px);
      max-width: 100%;
    }

    .el-picker-panel {
      &__body {
        min-width: 100%;
      }
    }
  }

  /* popper start */
  &.el-popper {
    &[x-placement^="bottom"] {
      margin-top: 10px;
    }

    &[x-placement^="top"] {
      margin-bottom: 10px;
    }

    .popper__arrow {
      display: none;
    }
  }

  /* popper end */

  .el-picker-panel {
    color: $color-grey-900;
  }

  .el-date-range-picker {
    /* calendar head start */
    &__header {
      border-bottom: $primary-border;
      padding: 16px 24px;
      height: auto;

      @include mobile {
        padding: 8px 16px;
        height: 48px;

        div {
          height: 32px;
        }
      }

      div {
        font-weight: bold;
        color: $color-grey-900;
      }

      .el-picker-panel__icon-btn {
        @include mobile {
          margin-top: 0;
          height: 32px;
        }

        &.el-icon-d-arrow-left,
        &.el-icon-d-arrow-right {
          display: none;
        }
      }
    }

    /* calendar head end */

    /* calendar body start */
    &__content {
      padding: 0;

      .disabled-arrow {
        display: none !important;
      }

      &.is-left {
        border: 0;
      }

      /* data table  */
      .el-date-table {
        padding: 18px;

        th {
          border-bottom: 0 !important;
        }

        span {
          font-weight: normal;
        }

        td {
          width: 38px;
          height: 38px;
          padding: 0;
          border-radius: 100%;
          border: 0 !important;
          background-color: transparent !important;
          transition: none !important;

          &.available {
            span {
              color: $color-grey-900 !important;
            }

            &:hover {
              background-color: transparent !important;

              span {
                border: 1px solid $color-grey-900 !important;
                background-color: #FFF !important;
              }
            }

            &.in-range {
              background-color: $color-grey-100 !important;
              border-radius: 0;

              span {
                border-radius: 0 !important;
                background-color: $color-grey-100 !important;
                border: none !important;
              }

              &.start-date {
                background-color: $color-grey-100 !important;
                border-top-left-radius: 100px !important;
                border-bottom-left-radius: 100px !important;

                > div {
                  background-color: transparent;
                  border-radius: 0;
                }

                span {
                  border: 1px solid $color-grey-900 !important;
                  background-color: #FFF !important;
                  border-radius: 100px !important;
                  color: $color-grey-900 !important;
                }

                &.today {
                  span {
                    color: $color-blue-500 !important;
                    font-weight: bold;
                  }
                }
              }

              &.end-date {
                background-color: #F3F4F6 !important;
                border-top-right-radius: 100px !important;
                border-bottom-right-radius: 100px !important;

                > div {
                  background-color: $color-grey-100 !important;
                }

                span {
                  border: 1px solid $color-grey-900 !important;
                  background-color: #FFF !important;
                  border-radius: 100px !important;
                }
              }

              // specific for tour radar product
              // start-date and end-date are the same
              &.start-date.end-date {
                > div {
                  background-color: transparent !important;
                }
              }
            }
          }

          &.normal {
            &.in-range {
              background-color: $color-grey-100 !important;
              border-radius: 0;
            }

            &.end-date {
              background-color: #FFF !important;
              border-top-right-radius: 100px !important;
              border-bottom-right-radius: 100px !important;

              > div {
                background-color: $color-grey-100 !important;
              }

              span {
                border: 1px solid $color-grey-900 !important;
                background-color: #FFF !important;
                border-radius: 100px !important;
              }
            }
          }

          &.today {
            span {
              color: $color-blue-500 !important;
              font-weight: bold;
            }
          }

          &.disabled {
            div,
            span {
              text-decoration: line-through;
              background: transparent;
            }
          }

          &.prev-month,
          &.disabled {
            div {
              color: $color-grey-300;
            }
          }

          div,
          span {
            height: 38px;
            width: 38px;
            padding: 0;
            line-height: 38px;
            margin: 0;
          }

          span {
            border: 1px solid transparent;
          }
        }

        /* seasonal marker date */
        .seasonal-marker-date-point {
          position: relative;

          & div {
            border-bottom: 2px solid;
            margin-left: 4px;
            border-radius: 0;
            box-sizing: content-box;
            margin-bottom: 0;
            width: 84%;
          }

          &.prev-month,
          &.next-month {
            & div {
              border-bottom: 0;
            }
          }

          &-lowseason {
            & div {
              border-bottom-color: $color-success;
            }
          }

          &-normalseason {
            & div {
              border-bottom-color: $color-grey-400;
            }
          }

          &-highseason {
            & div {
              border-bottom-color: $color-yellow-400;
            }
          }

          &-peakseason {
            & div {
              border-bottom-color: $color-yellow-600;
            }
          }
        }

        &__row {
          & td div {
            margin-bottom: 2px;
          }
        }
      }

      @include mobile {
        width: 100%;

        .el-date-table {
          padding: 16px;

          th {
            padding: 0;
          }

          td {
            width: 32px;
            height: 32px;

            div,
            span {
              height: 32px;
              width: 32px;
              line-height: 32px;
            }
          }
        }
      }
    }

    /* calendar body end */
  }

  /* seasonal marker toggle and caption */
  .seasonal-marker {
    &-info {
      background-color: $color-grey-100;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      gap: 16px;
    }

    &-toggle-container {
      position: relative;
      width: 48px;
      height: 28px;

      input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      .seasonal-marker-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #CCC;
        border-radius: 30px;
        transition: 0.4s;

        &::before {
          position: absolute;
          content: "";
          height: 24px;
          width: 24px;
          left: 3px;
          bottom: 2px;
          background-color: $color-white;
          border-radius: 50%;
          transition: 0.2s;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }
      }

      input:checked + .seasonal-marker-slider {
        background-color: $color-blue-500;
      }

      input:checked + .seasonal-marker-slider::before {
        transform: translateX(18px);
      }
    }

    &-caption-line {
      width: 12px;
      height: 2px;
      border-radius: 10px;
      display: inline-block;

      &-highseason {
        background-color: $color-yellow-400;
      }

      &-peakseason {
        background-color: $color-yellow-600;
      }

      &-normalseason {
        background-color: $color-grey-400;
      }

      &-lowseason {
        background-color: $color-green;
      }
    }
  }
}
