/** This Place For State Favorite **/
.#{$namespace} {
  & .icon-heart-o {
    &.active {
      &::before {
        content: "\EA90" !important;
        color: $color-semi-red;
      }
    }
  }
}

/* ************************************ */
