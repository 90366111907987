/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Home Explore **/
.#{$namespace} {
  & .c-home-explore {
    &:last-child {
      padding-bottom: 64px;
    }

    & .home-explore {
      &__container {
        & .t-panel-explore {
          height: 100%;
          padding: 0 10px;

          & .side-wrapper {
            background-color: white;

            &.left {
              padding-left: 16px;
            }

            &.right {
              padding-right: 16px;
            }

            & .t-panel-explore-item {
              padding: 24px 10px;

              & h2 {
                color: $color-grey-darker;
                font-size: 13px;
                font-weight: normal;
              }
            }
          }
        }
      }

      &__panel {
        position: relative;
      }

      &__list {
        position: relative;
        padding: 0 24px;
        width: 100%;
        display: table;

        @include clearfix;

        & .list {
          &-item {
            padding: 30px 16px;
            max-width: 146px;
            display: table-cell;

            & .img-container {
              height: 80px;
            }

            & > a {
              display: block;
            }

            & .image {
              height: 64px;
              max-width: 100%;
            }

            & .title {
              color: $color-grey-dark;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
}

/* ************************************ */
