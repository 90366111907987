/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Home Slider Tiles **/
.#{$namespace} {
  & .c-home-slider-tiles {
    margin-top: 64px;

    &:last-child {
      padding-bottom: 64px;
    }

    & .c-carousel-card-slider-tiles {
      & .carousel-card-slider-tiles {
        &__container {
          &-content {
            position: relative;
            width: 100%;

            & .content {
              &-main {
                position: relative;
                width: 100%;

                &.desktop {
                  display: block;
                }

                &.mobile {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    & .c-card {
      & .#{$prefix} {
        &-card-title {
          height: 210px;
          max-height: 210px;
        }
      }

      & .t-card-title-content.bottom {
        top: auto;
        bottom: 0;
        left: 0;

        @include transform(none !important);
      }

      & .description {
        font-size: 14px;
      }

      & .title {
        font-size: 24px;
      }
    }
  }
}

/* ************************************ */
