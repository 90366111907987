.#{$namespace} {
  .trv-discount-tag {
    background-color: $color-green-50;
    border-radius: $radius;
    color: $color-green;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding: 4px 8px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
}
