.c-promo-banner-layer-widget {
  .t-btn {
    display: inline-flex !important;
  }

  .t-inner-html {
    /deep/ {
      p {
        margin-bottom: 0;
      }
    }
  }
}
