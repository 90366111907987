/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Section Title Button **/
.#{$namespace} {
  & .c-section-title-button {
    position: relative;
    margin-bottom: 16px;

    & .section-title-button {
      &__action {
        border: 1px $color-secondary solid;
        border-radius: 4px;
        padding: 4px 8px;

        &:hover {
          background: $color-secondary;
          color: white;
        }
      }

      &__header {
        position: relative;
        width: 100%;
      }

      &__sub-header {
        position: relative;
        width: 100%;
      }
    }

    & .description {
      font-size: 16px;
      color: #999;
      margin-top: 8px;
    }

    & .sub-header {
      font-size: 16px;
      color: #999;
      margin-top: 8px;

      div,
      p,
      span {
        font-size: 16px;
        color: #999;
      }
    }
  }
}

/* ************************************ */
