@charset "utf-8";

@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";
@import "~/assets/scss/variables.scss";

$--color-primary: #2DCCD3;
$--font-path: '~element-ui/lib/theme-chalk/fonts';

@import "~element-ui/packages/theme-chalk/src/base";
@import "~element-ui/packages/theme-chalk/src/date-picker";
@import "~element-ui/packages/theme-chalk/src/input-number";
@import "~element-ui/packages/theme-chalk/src/slider";
@import "~element-ui/packages/theme-chalk/src/tooltip";
@import "~element-ui/packages/theme-chalk/src/dropdown";
@import "~element-ui/packages/theme-chalk/src/dropdown-menu";
@import "~element-ui/packages/theme-chalk/src/dropdown-item";
@import "~element-ui/packages/theme-chalk/src/notification";

// ElementUi Override
.travlr-ui {
  // Tooltip
  .el-tooltip__popper.is-dark {
    background-color: $color-grey-800 !important;
  }

  // Dropdown
  .el-dropdown-menu {
    min-width: 200px;
  }

  .el-dropdown-menu__item {
    padding: 8px 16px;
    color: $color-grey-900 !important;
  }

  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    background-color: $color-grey-lightest;
  }

  // Notification
  .el-notification__content {
    text-align: left !important;
  }
}
