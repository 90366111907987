@import "@/Library/UiKit/src/scss/uikit/utilities/variables/_variables.scss";

.#{travlr-ui} {
  & .form-input {
    &__label {
      display: block;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    &__field {
      background-color: $color-white;
      border-radius: $radius;
      border: solid 1px $color-grey-light;
      color: $color-grey-darkest;
      font-size: 16px;

      &:focus {
        border-color: $color-primary;
        box-shadow: none;
        outline: 0;
      }
    }

    &__icon {
      color: $color-grey-dark;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &--left {
        left: 12px;
      }

      &--right {
        cursor: pointer;
        right: 12px;
      }

      &.icon-search {
        font-size: 20px;
      }

      &.icon-location {
        font-size: 22px;
      }

      &.icon-close-circle {
        font-size: 20px;
      }

      &.icon-angle-down-small {
        font-size: 26px;
      }
    }

    &--large {
      & .form-input {
        &__field {
          height: 50px;
        }
      }
    }

    &--medium {
      & .form-input {
        &__field {
          height: 40px;
        }
      }
    }

    &--small {
      & .form-input {
        &__field {
          height: 32px;
        }
      }
    }

    &--icon {
      &.form-input {
        &--medium {
          & .form-input {
            &__group {
              position: relative;
            }

            &__field {
              padding-left: 40px;
              padding-right: 40px;
            }
          }
        }
      }
    }

    &--opened {
      .dropdown {
        opacity: 1;
        visibility: visible;
      }
    }

    &--white {
      & .form-input {
        &__field {
          background-color: $color-grey-lightest;
          padding: 12px;
          width: 100%;
        }
      }
    }

    &--error {
      & .form-input {
        &__field {
          border-color: $color-danger;
        }

        &__validation {
          font-size: 12px;
          letter-spacing: 0.2px;
          color: $color-danger;
          display: block;
          margin-top: 8px;
        }
      }
    }
  }
}
