/******************************************
  Some tips for creating blocks / modules :
  - Add `c` prefix before the name of block or modules
  - Or Just copy paste this structure of styling code into your new block or components styling file
  - Dont forget to register your block or modules into `_modules.scss`
******************************************/
/** This Place For Block Holiday **/
.#{$namespace} {
  & .c-home-holiday {
    &:last-child {
      padding-bottom: 64px;
    }

    & .c-card {
      & .#{$prefix} {
        &-card-title {
          height: 320px;
          max-height: 320px;

          &-content {
            & .title {
              font-size: 28px;
            }

            & .description {
              font-size: 16px;
              margin-top: 24px;
            }
          }
        }
      }

      & .t-card-background {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

/* ************************************ */
