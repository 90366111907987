@mixin icon-rotate($degrees, $rotation) {
  filter: progid:dximagetransform.microsoft.basicimage(rotation=#{$rotation});
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin icon-flip($horiz, $vert, $rotation) {
  filter: progid:dximagetransform.microsoft.basicimage(rotation=#{$rotation});
  -webkit-transform: scale($horiz, $vert);
  -moz-transform: scale($horiz, $vert);
  -ms-transform: scale($horiz, $vert);
  -o-transform: scale($horiz, $vert);
  transform: scale($horiz, $vert);
}

@mixin create-icon($icon-name, $icon-value) {
  .#{$travlr-ui} {
    .#{$travlr-prefix} {
      &icon {
        &.icon {
          &-#{$icon-name} {
            &::before {
              content: unicode($icon-value);
            }
          }
        }
      }
    }
  }
}

@mixin icon-default {
  display: inline-block;
  font: normal normal normal 24px/1 $typeface-travlr-icon !important;
  text-rendering: auto;

  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
