$travlr-ui: 'travlr-ui';
$travlr-prefix: 't-';
$travlr-class-migration: 'migration';
$element-prefix: 'el-';
$travlr-library-path: '../../../../Library/UiKit/';

// 1. Colour Pallete

// 1.1 Theme Colours
$color-primary:                     #2DCCD3 !default;
$color-primary-light:               #57DBE1 !default;
$color-primary-dark:                #1EB8BF !default;

$color-secondary:                   #6BA4B8 !default;
$color-secondary-light:             #81B9CD !default;
$color-secondary-dark:              #5D96AA !default;

$color-tertiary:                    #006272 !default;
$color-tertiary-light:              #047486 !default;
$color-tertiary-dark:               #015360 !default;

$color-semi-dark:                   #38404C !default;

// 1.2 Grayscale
$color-white:                       #FFFFFF !default;
$color-grey-lightest:               #F4F4F4 !default;
$color-grey-lighter:                #DDDDDD !default;
$color-grey-light:                  #CCCCCC !default;
$color-grey-med:                    #B6B9BB !default;
$color-grey-dark:                   #8E9497 !default;
$color-grey-darker:                 #666C6F !default;
$color-grey-darkest:                #374044 !default;
$color-black:                       #000000 !default;
$color-disabled:                    #E5E7EB !default;

$color-grey-50:                     #F9FAFB !default;
$color-grey-100:                    #F3F4F6 !default;
$color-grey-200:                    #E5E7EB !default;
$color-grey-300:                    #D1D5DB !default;
$color-grey-400:                    #9CA3AF !default;
$color-grey-500:                    #6B7280 !default;
$color-grey-600:                    #4B5563 !default;
$color-grey-700:                    #374151 !default;
$color-grey-900:                    #111827 !default;

// 1.3 Alerts
$color-danger:                      #F45252 !default;
$color-warning:                     #F59E0B !default;
$color-success:                     #10B981 !default;

// 2. Typography

// 2.1 Typeface
$typeface-primary:                  "Montserrat", sans-serif !default;
$typeface-sans-serif:               blinkmacsystemfont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;
$typeface-monospace:                monospace !default;
$typeface-code:                     $typeface-monospace !default;
$typeface-travlr-icon:              "TRAVLR" !default;

// 2.2 Hierarchy
$weight-bold:                       700 !default;
$weight-semibold:                   600 !default;
$weight-regular:                    400 !default;

$size-text-1:                       32px !default;
$size-text-2:                       28px !default;
$size-text-3:                       24px !default;
$size-text-4:                       20px !default;
$size-text-5:                       16px !default;
$size-text-6:                       14px !default;
$size-text-body:                    14px !default;
$size-text-caption:                 12px !default;
$size-text-small:                   10px !default;
$size-text-label-text:              20px !default;
$size-text-label-text-small:        16px !default;

$line-height-text-1:                36px !default;
$line-height-text-2:                32px !default;
$line-height-text-3:                28px !default;
$line-height-text-4:                24px !default;
$line-height-text-5:                20px !default;
$line-height-text-6:                18px !default;
$line-height-text-body:             20px !default;
$line-height-text-supplementary:    18px !default;
$line-height-text-callout:          18px !default;
$line-height-text-callout-small:    15px !default;
$line-height-text-label-text:       24px !default;
$line-height-text-label-text-small: 20px !default;
$line-height-text-caption:          16px !default;

// 3. Container
$container-width:                   1200px !default;
$container-gap:                     8px !default;
$container-gap-mobile:              8px !default;

// new grid system
$grid-gutter-width:                 8px !default;
$max-col:                           12 !default;

// 4. Responsiveness
/** For Extra Small Screen / Phone **/
$screen-xs:                         767px !default;
$screen-xs-min:                     $screen-xs !default;
$screen-phone:                      $screen-xs-min !default;

/** For Small Screen / Tablet **/
$screen-sm:                         768px !default;
$screen-sm-min:                     $screen-sm !default;
$screen-tablet:                     $screen-sm-min !default;
$screen-tablet-landscape:           1024px !default;

/** For Medium Screen / Desktop **/
$screen-md:                         992px !default;
$screen-md-min:                     $screen-md !default;
$screen-desktop:                    $screen-md-min !default;

/** For Large Screen / Wide Desktop **/
$screen-lg:                         1200px !default;
$screen-lg-min:                     $screen-lg !default;
$screen-wd-desktop:                 $screen-lg-min !default;

/** For Extra Large Screen / Fullhd Desktop **/
$screen-xl:                         1344px !default;
$screen-xl-min:                     $screen-xl !default;
$screen-xl-desktop:                 $screen-xl-min !default;

/** For Screen Max **/
$screen-xs-max:                     ($screen-sm-min - 1) !default;
$screen-sm-max:                     ($screen-md-min - 1) !default;
$screen-md-max:                     ($screen-lg-min - 1) !default;
$screen-lg-max:                     ($screen-xl-min - 1) !default;

// New grid system
$trv-screen-xs:                      0;
$trv-screen-sm:                      576px;
$trv-screen-md:                      768px;
$trv-screen-lg:                      992px;
$trv-screen-xl:                      1180px;

$trv-screen-xs-max:                  ($trv-screen-sm - 1);
$trv-screen-sm-max:                  ($trv-screen-md - 1);
$trv-screen-md-max:                  ($trv-screen-lg - 1);
$trv-screen-lg-max:                  ($trv-screen-xl - 1);

$phone: $screen-phone;
$tablet: $screen-tablet;
$desktop: $screen-desktop;
$widescreen: $screen-wd-desktop;
$widescreen-enabled: true !default;
$fullhd: $screen-xl-desktop !default;
$fullhd-enabled: true !default;

// 5. Icons
$font-montserrat-path:               'uikit/fonts/Montserrat/source/' !default;
$icon-font-path:                    "../fonts" !default;
$icon-background-color:           #EEE !default;
$icon-li-margin-right:              0.4em !default;

// Miscellaneous
$primary-border:                    1px solid $color-grey-300 !default;
$primary-border-color:              $color-grey-300 !default;
$primary-panel-padding:             16px !default;
$primary-box-padding:               24px !default;
$primary-box-padding-small:         16px !default;

$primary-box-shadow:                0 1px 2px 0 rgba(0, 0, 0, 0.2) !default;
$secondary-box-shadow:              0 2px 8px 0 rgba(0, 0, 0, 0.1);

$header-height:                     64px !default;

$hero-main-height:                  440px !default;

$easing:                            ease !default;
$primary-transition:                all 0.5s $easing;

$radius:                            8px !default;
$radius-small:                      4px !default;
$radius-large:                      12px !default;

$button-padding:                    8px 16px !default;
$button-padding-small:              4px 8px !default;
$button-padding-large:              12px 24px !default;
$button-font-size:                  16px !default;
$button-font-size-small:            14px !default;
$button-font-size-large:            18px !default;

$avatar-width:                      80px !default;
$avatar-height:                     80px !default;
$avatar-width-extra-small:          24px !default;
$avatar-height-extra-small:         24px !default;
$avatar-width-small:                48px !default;
$avatar-height-small:               48px !default;
$avatar-width-large:                100px !default;
$avatar-height-large:               100px !default;

$thumb-width-small:                 120px !default;
$thumb-height-small:                75px !default;
$thumb-width-medium:                200px !default;
$thumb-height-medium:               125px !default;
$thumb-width-large:                 320px !default;
$thumb-height-large:                200px !default;

$section-extra-small:               24px 0 !default;
$section-small:                     32px 0 !default;
$section-medium:                    40px 0 !default;
$section-large:                     48px 0 !default;
$section-extra-large:               64px 0 !default;

$input-border-color:                $color-grey-light;
$input-border-hover:                #999 !default;
$input-border-focus:                $color-primary;

$icon-small:                        16px !default;
$icon-medium:                       20px !default;
$icon-large:                        28px !default;

$modal-padding:                     24px !default;

$notification-position-top:         88px !default;
$notification-position-right:       24px !default;
$notification-position-left:        24px !default;
$notification-position-bottom:      24px !default;

$form-height-small:                 32px !default;
$form-height-medium:                40px !default;
$form-height-large:                48px !default;