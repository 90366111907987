.c-action-button-layer-widget {
  .t-btn {
    .c-image {
      height: auto !important;
      width: auto !important;
    }
  }

  .cta-icon {
    max-width: 24px;
    max-height: 24px;
    object-fit: contain;
  }
}
